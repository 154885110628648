import { Link } from "react-router-dom";

const Professional = ({ slug, name, role }) => {
  return (
    <>
      <Link to={`/professionals/${slug}`} className="flex flex-col items-center gap-3 sm:gap-5 max-sm:w-32">
        <img src={`./img/headshot/${slug}.png`} alt={slug} />
        <div className="text-sm sm:text-xl">{name}</div>
        <div
          className="w-full py-1 text-sm font-semibold text-center uppercase max-sm:text-xs bg-gray-thi text-gray-pri">
          {role}
        </div>
      </Link>
    </>
  );
}

export default Professional;