const SidePanel = () => {
  return (
    <>
      <div className="w-[46vw] flex flex-col items-center justify-center bg-[#FFFFFFAA] border-success-right max-lg:hidden">
        <img className="max-md:w-24" src="/img/logo_large_black.png" alt="logo" />
        <div className="mt-8 text-sm font-semibold sm:text-3xl">Big city experience, <br />small town service.</div>
      </div>
    </>
  );
}

export default SidePanel;