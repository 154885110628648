import Modal from "../../UI/Modal";

const InterestRate = ({ ...props }) => {
  return (
    <>
      <Modal {...props} size='sm'>
        <div className="flex flex-col gap-3">
          <div>Interest Rate:  </div>
          <div>
            An amount earned on a Convertible Debt Holder’s principal, according to a specified rate. This does not include any compounding interest. Each day interest is calculated on the unpaid principal balance and becomes “accrued interest.”          </div>
          <div>
            Interest begins to accrue at the time the convertible debt instrument is executed between the company and the Convertible Debt Holder.
          </div>
          <div>
            At the time of conversion, all of the accrued interest will be capitalized (added to the principal).
          </div>
        </div>
      </Modal>
    </>
  );
}

export default InterestRate;