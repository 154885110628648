import { useState, useEffect } from "react";
import { goToIndex } from "@/redux/progressSlice";
import { updateData } from "@/redux/dataSlice";
import { useSelector, useDispatch } from 'react-redux';
import { setOffsetBack } from "../../utils";

const SeriesAInvest = ({ index }) => {

  const [isFilled, setIsFilled] = useState(false);
  const dispatch = useDispatch();
  const activeIndex = useSelector((state) => state.progress.activeIndex);
  const investors = useSelector((state) => state.data.form.investors);

  const goToNext = () => {
    dispatch(goToIndex(activeIndex + 1));
    setIsFilled(false);
  }

  useEffect(() => {
    if (investors?.length > 0) {
      if (activeIndex === index) {
        setIsFilled(
          investors.every(investor => investor.series_a)
        );
      } else {
        setIsFilled(false);
      }
    }
  }, [investors, activeIndex, index]);

  const updateSeriesA = (index) => (e) => {
    const newInvestors = JSON.parse(JSON.stringify(investors));
    newInvestors[index].series_a = e.target.innerHTML;

    dispatch(updateData({
      investors: newInvestors
    }));

    // Calcuate the current offset of the cursor
    setOffsetBack(e.target);
  }

  const toggleToDisable = (index) => (e) => {
    const newInvestors = JSON.parse(JSON.stringify(investors));
    const investor = newInvestors[index];
    if (investor.series_a !== '_') {
      investor.series_a = '_';
    } else {
      investor.series_a = '';
    }

    dispatch(updateData({
      investors: newInvestors
    }));
  }

  return (
    <>
      <div className="mt-2 flex">
        <table className="striped input-table w-[500px]">
          <thead>
            <tr>
              <th>Pre-Series A Investors</th>
              <th>New money Series A</th>
            </tr>
          </thead>
          <tbody>
            {
              investors && investors.map((investor, index) => (
                <tr key={index} disabled={investor.series_a === '_'}>
                  <td className="uneditable" onClick={toggleToDisable(index)}>
                    {index + 1}. {investor.fullname}
                  </td>
                  <td className="editable" onInput={updateSeriesA(index)} data-placeholder="$0.00">
                    {investor.series_a}
                  </td>
                </tr>
              ))
            }
          </tbody>

          <colgroup>
            <col />
            <col />
          </colgroup>
        </table>
        {
          isFilled && (
            <button className="success-btn w-9 shrink-0" onClick={goToNext}>
              <img src="/img/icons/arrow-right.svg" className='mx-auto' alt="arrow-right" />
            </button>
          )
        }
      </div>
    </>
  );
}

export default SeriesAInvest;