import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

const ErrorModal = ({ open, text, setOpen }) => {
  const [show, setShow] = useState(open || false);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    setShow(open);
    setErrorMsg(text);
  }, [open, text]);

  return (
    <>
      {
        show && (
          <div className="absolute top-0 left-0 z-10 w-full h-full font-inter">
            <div className="absolute top-0 left-0 w-full h-full bg-[#A6A6A699]" />
            <div className="modal-body max-w-[450px] p-5 mx-auto flex h-full items-center ">
              <div className="relative flex flex-col w-full gap-5 p-5 bg-white md:px-8 md:py-9">
                <div className="font-bold">Invalid response.</div>
                <div>{errorMsg}</div>
                <div>Do you want to exit the Capitalization Table Evolution Generator? Progress will not be saved.</div>

                <div className="flex justify-around mt-3 text-sm">
                  <button className="outline-btn-black" onClick={() => setOpen(false)}>NO, GO BACK</button>
                  <Link to="/">
                    <button className="error-btn">YES, EXIT</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  )
}

export default ErrorModal;