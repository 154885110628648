import Modal from "../../UI/Modal";

const PreSeriesAInvestors = ({ ...props }) => {
  return (
    <>
      <Modal {...props} size='sm'>
        <div className="flex flex-col gap-3">
          <div>Pre-Series A Investors: </div>
          <div>
            Individually, a "Convertible Debt Holder" and collectively, the "Pre-Series A Investors"
          </div>
        </div>
      </Modal>
    </>
  );
}

export default PreSeriesAInvestors;