import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  form: {}
};

export const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    updateData: (state, action) => {
      const data = action.payload;
      state.form = { ...state.form, ...data };
    },
    clearData: (state) => {
      state.form = {};
    }
  },
});

export const { clearData, updateData } = dataSlice.actions;

export default dataSlice.reducer;