import CommonStock from "./CommonStock";
import CommonStockOptions from "./CommonStockOptions";
import OptionPool from "./OptionPool";
import ConvertibleNotes from "./ConvertibleNotes";
import PremoneyValuation from "./PremoneyValuation";
import SeriesAOtherInvestors from "./SeriesAOtherInvestors";
import PreSeriesAInvestors from "./PreSeriesAInvestors";
import PrincipalAmount from "./PrincipalAmount";
import DiscountRate from "./DiscountRate";
import InterestRate from "./InterestRate";
import ConversionPriceCap from "./ConversionPriceCap";

const Terms = {
  'common stock': CommonStock,
  'common options': CommonStockOptions,
  'option pool': OptionPool,
  'convertible notes': ConvertibleNotes,
  'pre-money valuation': PremoneyValuation,
  'Series A Other Investors': SeriesAOtherInvestors,
  'Pre-Series A Investors': PreSeriesAInvestors,
  'Principal Amount': PrincipalAmount,
  'Discount Rate': DiscountRate,
  'Interest Rate': InterestRate,
  'Conversion Price Cap': ConversionPriceCap
}

export default Terms;