import { useState, useEffect } from "react";
import { goToIndex } from "@/redux/progressSlice";
import { updateData } from "@/redux/dataSlice";
import { useSelector, useDispatch } from 'react-redux';
import { setOffsetBack } from "../../utils";

const SeriesBInvest = ({ index }) => {

  const [isFilled, setIsFilled] = useState(false);
  const dispatch = useDispatch();
  const activeIndex = useSelector((state) => state.progress.activeIndex);
  const formData = useSelector((state) => state.data.form || []);
  const { series_a_lead_investor, series_a_other_investors } = formData;
  const investors = useSelector((state) => state.data.form.investors || []);

  const goToNext = () => {
    dispatch(goToIndex(activeIndex + 1));
    setIsFilled(false);
  }

  useEffect(() => {
    if (investors.length > 0) {
      if (activeIndex === index) {
        const seriesAFilled = Boolean(series_a_lead_investor[2] && series_a_other_investors[1]);

        setIsFilled(
          investors.every(investor => investor.series_b) && seriesAFilled
        );
      } else {
        setIsFilled(false);
      }
    }
  }, [investors, activeIndex, index, series_a_lead_investor, series_a_other_investors]);

  const updateSeriesB = (index) => (e) => {
    const newInvestors = JSON.parse(JSON.stringify(investors));
    newInvestors[index].series_b = e.target.innerHTML;

    dispatch(updateData({
      investors: newInvestors
    }));

    // Calcuate the current offset of the cursor
    setOffsetBack(e.target);
  }

  const updateTableData = (dataKey, index) => (e) => {
    const target = e.target;
    const value = target.innerHTML || 0;

    // Update Redux Storage
    let newData;
    if (index) {
      newData = [...formData[dataKey]];
      newData[index] = value
    } else {
      newData = value;
    }

    dispatch(updateData({
      [dataKey]: newData
    }));

    setOffsetBack(target);
  }

  const toggleToDisable = (index) => (e) => {
    const newInvestors = JSON.parse(JSON.stringify(investors));
    const investor = newInvestors[index];
    if (investor.series_b !== '_') {
      investor.series_b = '_';
    } else {
      investor.series_b = '';
    }

    dispatch(updateData({
      investors: newInvestors
    }));
  }

  return (
    <>
      <div className="mt-2 flex">
        <table className="striped input-table w-[500px]">
          <thead>
            <tr>
              <th>Series A Investors</th>
              <th>New money Series B</th>
            </tr>
          </thead>
          <tbody>
            {
              investors.map((investor, index) => (
                <tr key={index} disabled={investor.series_b === '_'}>
                  <td className="uneditable" onClick={toggleToDisable(index)}>
                    {index + 1}. {investor.fullname}
                  </td>
                  <td className="editable" onInput={updateSeriesB(index)} data-placeholder="$0.00">
                    {investor.series_b}
                  </td>
                </tr>
              ))
            }
            {
              series_a_lead_investor && (
                <>
                  <tr>
                    <td className="uneditable">
                      {investors.length + 1}. {series_a_lead_investor[0]}
                    </td>
                    <td className="editable" data-placeholder="$0.00" onInput={updateTableData('series_a_lead_investor', 2)}>

                    </td>
                  </tr>
                  <tr>
                    <td className="uneditable">
                      {investors.length + 2}. Series A Other Investor(s)
                    </td>
                    <td className="editable" data-placeholder="$0.00" onInput={updateTableData('series_a_other_investors', 1)}>

                    </td>
                  </tr>
                </>
              )
            }

          </tbody>

          <colgroup>
            <col />
            <col />
          </colgroup>
        </table>
        {
          isFilled && (
            <button className="success-btn w-9 shrink-0" onClick={goToNext}>
              <img src="/img/icons/arrow-right.svg" className='mx-auto' alt="arrow-right" />
            </button>
          )
        }
      </div>
    </>
  );
}

export default SeriesBInvest;