import { useRoutes } from "react-router-dom";
import HomePage from "@/pages/HomePage";
import Professionals from "@/pages/Professionals";
import Professional from "@/pages/Professional";
import Services from "@/pages/Services";
import Contact from "@/pages/Contact";
import Disclaimer from "@/pages/Disclaimer";
import Capitalization from "@/pages/Capitalization";
import PrivacyPolicy from "@/pages/PrivacyPolicy";
import TermsPolicy from "@/pages/TermsPolicy";

const Routes = () => {
  const router = [
    {
      path: "/",
      element: <HomePage />,
    },
    {
      path: "/professionals",
      element: <Professionals />,
    },
    {
      path: "/professionals/:slug",
      element: <Professional />,
    },
    {
      path: "/services",
      element: <Services />,
    },
    {
      path: "/services/capital",
      element: <Disclaimer />,
    },
    {
      path: "/services/capitalization",
      element: <Capitalization />,
    },
    {
      path: "/contact",
      element: <Contact />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/terms-policy",
      element: <TermsPolicy />,
    }
  ];

  return useRoutes(router);
};

export default Routes;
