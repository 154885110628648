import Modal from "../UI/Modal";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '@/assets/styles/toast.scss';

const DisclaimerModal = ({ open, setOpen }) => {
  const [disabled, setDisabled] = useState(true);
  const navigate = useNavigate();

  const goCapitalization = () => {
    if (window.matchMedia('(min-width:1280px)').matches) {
      navigate('/services/capitalization');
    } else {
      toast.info("This service is only available on desktop!");
    }
  }

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="my-3 text-lg font-bold sm:my-6 sm:text-xl">
        Disclaimer
      </div>
      <div className="flex flex-col gap-4 max-sm:text-sm">
        <p>The Wright Squared Capitalization Table Documents (as defined below) are provided for your reference only and have been developed for hypothetical companies incorporated in Delaware.</p>
        <p>You acknowledge and agree that the making available of these CTEG documents (the “Wright Squared Capitalization Table Documents”) to you by Wright Squared, PLLC (“Wright Squared”) shall not create any attorney-client or other confidential or special relationship between you and Wright Squared. You should seek advice from an attorney licensed in the relevant jurisdiction(s), as well as a tax professional, before relying on Wright Squared Capitalization Table Documents. </p>
        <p>Additionally, the information provided in the Wright Squared Capitalization Table Documents does not constitute tax advice. Any discussion of tax matters is not intended or written to be used, and cannot be used, for the purpose of avoiding penalties under the Internal Revenue Code (or equivalent in the relevant jurisdiction) or promoting, marketing or recommending to another party any transaction or matter.</p>
        <p>You further agree and acknowledge that the Wright Squared Capitalization Table Documents have not been prepared with your specific deal or investor terms in mind, may not be suitable for use based on prior financing, and do not constitute tax advice. You assume all risk and liability that may result by relying on these documents. Review all documents carefully for accuracy before using them. </p>
        <p>WRIGHT SQUARED PROVIDES THESE TERMS ON AN “AS IS” BASIS, AND SPECIFICALLY DISCLAIMS ALL WARRANTIES, TERMS, REPRESENTATIONS AND CONDITIONS WHETHER EXPRESS, IMPLIED, OR STATUTORY, AND INCLUDING ANY WARRANTIES, TERMS, REPRESENTATIONS AND CONDITIONS OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR NONINFRINGEMENT.</p>
        <p>
          <input type="radio" id="agree" onChange={() => setDisabled(false)} />
          <label className="ml-2" htmlFor="agree">By clicking Continue, I agree to the Disclaimer.</label>
        </p>
      </div>
      <div className="flex justify-center mt-4 sm:mt-8">
        <button disabled={disabled} onClick={goCapitalization} className="w-40 mx-auto success-btn">CONTINUE</button>
      </div>
      <ToastContainer />
    </Modal >
  );
}

export default DisclaimerModal;