import SidePanel from '../components/SidePanel';
import { useNavigate } from "react-router-dom";

const Services = () => {
  const services = [
    'Asset Structuring',
    'Business Associations',
    'Corporate Finance',
    'Corporate Governance',
    'Federal Tax Controversy & Procedure',
    'Securities Regulation',
    'Venture Capital*',
    'Web3 and Digital Assets (Blockchain & Crypto)',
    'White Collar Defense and Investigations',
  ];
  const navigate = useNavigate();

  const goToService = (index) => {
    if (index === 6) {
      navigate('/services/capital');
    }
  }

  return (
    <>
      <div className="flex main sm:h-[90vh] tab-active">
        <SidePanel />
        <div className="w-full h-full overflow-auto lg:w-[54vw] flex justify-center 2xl:items-center py-8 sm:py-12 px-5 bg-white text-sm sm:text-base xl:text-lg">
          <div className="w-[640px] h-fit-content">
            <div>
              The Wright Squared team thrives to focus unstructured situations and provide clients strategic business advice that aligns with the organization’s risk profile. We prioritize a culture of innovation and client-centric service, working collaboratively to deliver tailored legal solutions. Our team has over sixty years of combined experience in the following areas:
            </div>
            <div className="grid grid-cols-3 gap-1 mt-6 sm:mt-12 max-sm:text-xs">
              {
                services.map((service, index) => (
                  <div key={index} onClick={() => goToService(index)} className={`service-${index} flex items-center px-3 sm:px-5 border-gray-sec justify-center text-center h-24 sm:h-32`}>
                    {service}
                  </div>
                ))
              }
            </div>
            <div className="mt-6 sm:mt-12">
              *Interact with the Venture Capital box to try our Capitalization Table Evolution Generator.
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Services;