import { Link, NavLink, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

const Header = () => {
  const location = useLocation();
  const [burgerActive, setBurgerActive] = useState(false);
  const [pathname, setPathname] = useState(location.pathname);
  const navLinks = {
    'Professionals': '/professionals',
    'Services': '/services',
    'CONTACT': '/contact',
  }
  const toogleBurger = () => {
    const mainEl = document.querySelector('.main');
    if (burgerActive) {
      mainEl.classList.remove('burger-active');
    } else {
      mainEl.classList.add('burger-active');
    }

    setBurgerActive(!burgerActive);
  }

  const moveMarker = (e) => {
    moveMarkerToElement(e.target);
  }

  const moveMarkerToElement = (element) => {
    const marker = document.querySelector('.active-marker');
    const linkRect = element.getBoundingClientRect();
    const navRect = document.querySelector('.nav-menu').getBoundingClientRect();

    marker.style.width = `${linkRect.width}px`;
    marker.style.left = `${linkRect.left - navRect.left}px`;
  }

  const hideMarker = () => {
    const marker = document.querySelector('.active-marker');
    marker.style.width = `0px`;
  }

  useEffect(() => {
    const handleResize = () => {
      const activeLink = document.querySelector('.nav-menu a.active');
      activeLink && moveMarkerToElement(activeLink);
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (Object.values(navLinks).some(navLink => location.pathname.indexOf(navLink) !== -1)) {
      if (pathname === '/') {
        const mainEl = document.querySelector('.main');
        mainEl.classList.add('animated');
      }
    } else {
      hideMarker();
    }

    setPathname(location.pathname);
    setBurgerActive(false);
  }, [location.pathname]);

  return (
    <>
      <div className="flex max-sm:justify-between px-5 sm:px-16 h-[10vh] items-center border-bottom-gray-sec relative bg-white z-10">
        <Link className="pr-20 md:w-1/2" to='/'>
          <img className="w-16" src='/img/icons/logo_small_black.svg' alt="logo" />
        </Link>
        <div className={`${burgerActive ? 'active' : ''} flex sm:h-full items-center w-1/2 font-semibold text-center uppercase nav-menu max-sm:!hidden`}>
          <div className="active-marker"></div>
          {
            Object.entries(navLinks).map(([label, link], key) => (
              <NavLink onClick={moveMarker} className="w-full px-4" activeClassName="active" key={key} to={link}>{label}</NavLink>
            ))
          }
        </div>
        <div
          className={`${burgerActive ? 'active' : ''} burger sm:hidden`}
          onClick={toogleBurger}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div >
    </>
  );
}

export default Header;