import "./style.scss";

const ProgressBar = ({ value, max, label, width }) => {
  return (
    <>
      <div className={`flex flex-col items-center gap-1 ${width}`}>
        <progress value={value} max={max} className={`${value > max ? 'disabled' : ''}`}></progress>
        <div className={`text-xs ${value > 0 ? 'font-bold' : 'text-gray-pri'}`}>{label}</div>
      </div >
    </>
  );
}

export default ProgressBar;