import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div
        className="footer absolute bottom-0 flex items-center justify-between w-full px-5 text-white sm:px-16 xl:px-32 max-sm:bg-black max-sm:text-white max-sm:text-[10px]">
        <div className="flex gap-3 py-4 sm:gap-8 sm:py-7">
          <div>© 2024 Wright Squared, PLLC</div>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/terms-policy">Terms of Service</Link>
        </div>
        <a rel="noreferrer" target="_blank" href="https://www.linkedin.com/company/wright-squared">
          <img className="max-sm:invert max-sm:w-4" src="./img/icons/linkedin.svg" alt="linkedin" />
        </a>
      </div>
    </>
  );
}

export default Footer;