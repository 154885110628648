const questions = [
  {
    'slug': 'series_a_right',
    'que': 'Do Pre-Series A Investors have a right to invest new money and participate in Series A financing?',
    'input_type': 'radio',
    'options': [
      'Yes',
      'No',
    ]
  },
  {
    'slug': 'series_a_invest_exist',
    'que': 'Will Pre-Series A Investors invest new money and participate in Series A financing?',
    'input_type': 'radio',
    'options': [
      'Yes',
      'No',
    ]
  },
  {
    'slug': 'series_a_invest',
    'que': 'Complete the following table for each Convertible Debt Holder participating in Series A financing:',
    'input_type': 'custom'
  },
  {
    'slug': 'premoney_valuation',
    'que': 'What is the assumed <span class="term">pre-money valuation</span> at the time of Series A financing?',
    'input_type': 'text',
    'placeholder': '$ 0.00'
  },
  {
    'slug': 'series_a_option_pool',
    'que': 'How many shares will the company expand the Series A <span class="term">option pool</span> by, if any (added prior to Series A share price calculation)?',
    'input_type': 'text',
    'placeholder': '0.00'
  },
  {
    'slug': 'series_a_lead_investor',
    'que': 'Name of the Series A Lead Investor and the investment amount:',
    'input_type': 'text_group',
    'max_num': 2,
    'placeholders': [
      'Full name',
      '$0.00'
    ]
  },
  {
    'slug': 'series_a_other_investors',
    'que': 'What is the cumulative amount invested by <span class="term">Series A Other Investors</span>?',
    'input_type': 'text',
    'placeholder': '$0.00'
  },
];

export default questions;