import Modal from "../../UI/Modal";

const CommonStock = ({ ...props }) => {
  return (
    <Modal {...props}>
      <div className="flex flex-col gap-3">
        <div>Common stock: </div>
        <div>
          Common stock is a security that represents the basic unit of ownership in a company. In Delaware, dual-class common stock structures are permitted, and one or more classes or series of stock may be authorized and issued. For the purposes of the CTEG, we assume only one class of common stock is authorized and issued.
        </div>
        <div>
          In general, holders of common stock exercise control by electing a board of directors and voting on corporate actions, as outlined in the company’s governing documents.
        </div>
        <div>
          Characteristics of common stock include dividend and voting rights:
        </div>
        <ul className="ml-5 list-disc">
          <li>
            Dividends are the right to share on a pro-rata basis in the net assets or profit distributions of the company.
          </li>
          <li>
            Voting rights may be modified or removed by creating additional classes of commons stock such as Class A voting and Class B nonvoting.
          </li>
        </ul>
        <div>
          In terms of priority, holders of common stock are at the bottom of the ladder based on the company’s ownership structure. In the event of liquidation, such as a sale or dissolution, holders of common stock only have rights to the company’s assets after outside creditors, inside creditors, and  preferred shareholders are made whole.
        </div>
      </div>
    </Modal>
  );
}

export default CommonStock;