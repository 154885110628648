import Professional from "../components/ProfessionalCard";
import SidePanel from '../components/SidePanel';

const Professionals = () => {
  return (
    <>
      <div className="flex main sm:h-[90vh] tab-active">
        <SidePanel />
        <div className="w-full lg:w-[54vw] h-full overflow-scroll flex flex-col 2xl:justify-center py-20 bg-white">
          <div className="flex justify-center gap-12 px-10 lg:gap-40">
            <Professional key={1} slug='del' name='Del Wright, Jr.' role='managing member' />
            <Professional key={2} slug='cheryl' name='Cheryl Wright' role='member' />
          </div>
          <div className="flex justify-center mt-8 sm:mt-16">
            <Professional slug='nihit' name='Nihit Desai' role='ASSOCIATE' />
          </div>
        </div>
      </div>
    </>
  );
}

export default Professionals;