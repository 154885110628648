import { Link } from "react-router-dom";
import "./style.scss";
import privacyPolicy from "@/assets/html/privacy-policy.html";
import termsPolicy from "@/assets/html/terms-policy.html";

const policies = {
  'privacy': privacyPolicy,
  'terms': termsPolicy
};

const PolicyModal = ({ type }) => {

  return (
    <>
      <div className="max-w-[1100px] p-5 mx-auto flex h-full items-center ">
        <div className="relative w-full h-[70vh] overflow-y-scroll bg-white">
          <Link to="/" className="absolute top-4 right-4">
            <img className="w-3 h-3" src="/img/icons/xmark.svg" alt="close" />
          </Link>
          <div className="max-sm:text-sm" dangerouslySetInnerHTML={{ __html: policies[type] }} />
        </div>
      </div>
    </>
  );
}

export default PolicyModal;