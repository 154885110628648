import { useState, useEffect } from "react";
import { goToIndex } from "@/redux/progressSlice";
import { updateData } from "@/redux/dataSlice";
import { useSelector, useDispatch } from 'react-redux';
import DatePicker from "react-datepicker";
import moment from 'moment';

const ConvertibleDebt = ({ index }) => {

  const [investors, setInvestors] = useState([{}]);
  const [isFilled, setIsFilled] = useState(false);
  const dispatch = useDispatch();
  const activeIndex = useSelector((state) => state.progress.activeIndex);
  const series_a_date = useSelector((state) => state.data.form.series_a_date);

  const goToNext = () => {
    dispatch(updateData({
      investors
    }));
    dispatch(goToIndex(activeIndex + 1));
  }

  useEffect(() => {
    if (index === activeIndex) {
      const keyCount = 6;

      setIsFilled(
        investors.every(investor => {
          const keys = Object.keys(investor);
          if (keys.length !== keyCount) {
            return false;
          }

          return keys.every(key => {
            const value = investor[key];
            return Boolean(value);
          });
        })
      );
    } else {
      setIsFilled(false);
    }
  }, [investors, index, activeIndex]);

  const addInvestor = () => {
    setInvestors([...investors, {}]);
  }

  const updateInvestor = (key, index) => (e) => {
    const newInvestors = [...investors];
    newInvestors[index][key] = e.target.innerHTML;

    setInvestors(newInvestors);

    // Calcuate the current offset of the cursor
    const node = e.target.firstChild;
    const range = window.getSelection().getRangeAt(0);
    const curOffset = range.endOffset;

    requestAnimationFrame(() => {
      if (node) {
        const range = document.createRange();
        const sel = window.getSelection();
        range.setStart(node, curOffset);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
      }
    });
  }

  const deleteInvestor = (index) => (e) => {
    const btnSize = 20;
    const paddingTop = 9;
    const paddingRight = 12;
    const rect = e.target.getBoundingClientRect();
    const clickTop = rect.right - e.clientX;
    const clickRight = e.clientY - rect.top;

    if (clickTop > paddingTop && clickTop < paddingTop + btnSize && clickRight > paddingRight && clickRight < paddingRight + btnSize) {
      const newInvestors = [...investors];
      newInvestors.splice(index, 1);
      setInvestors(newInvestors);
    }
  }

  const updateDateIssue = (index) => (date) => {
    const newInvestors = [...investors];
    newInvestors[index]['date_issue'] = moment(date).format('MM/DD/YYYY');

    setInvestors(newInvestors);
  }

  return (
    <>
      <div className="mt-2 flex">
        <table className="striped input-table convertible-debt w-[950px]">
          <thead>
            <tr>
              <th><span className="term">Pre-Series A Investors</span></th>
              <th><span className="term">Principal Amount</span></th>
              <th>Date of Issuance</th>
              <th>Date of Series A Financing</th>
              <th><span className="term">Interest Rate</span></th>
              <th><span className="term">Discount Rate</span></th>
              <th><span className="term">Conversion Price Cap</span></th>
            </tr>
          </thead>
          <tbody>
            {
              investors.map((investor, index) => (
                <tr key={index}>
                  <td onInput={updateInvestor('fullname', index)} onClick={deleteInvestor(index)} data-placeholder={`${index + 1}. Full Name`}>
                    {investor.fullname}
                  </td>
                  <td onInput={updateInvestor('principal', index)} data-placeholder="$0.00">
                    {investor.amount}
                  </td>
                  <td data-placeholder="--/--/----">
                    <DatePicker className='cteg-datepicker' placeholderText='MM/DD/YYYY' onChange={updateDateIssue(index)} selected={new Date(investor.date_issue || Date.now())} />
                  </td>
                  <td className="uneditable" onInput={updateInvestor('date_series_a', index)}>{series_a_date}</td>
                  <td onInput={updateInvestor('interest', index)} data-placeholder="%">
                    {investor.interest}
                  </td>
                  <td onInput={updateInvestor('discount', index)} data-placeholder="%">
                    {investor.discount}
                  </td>
                  <td onInput={updateInvestor('price_cap', index)} data-placeholder="$0.00">
                    {investor.price_cap}
                  </td>
                </tr>
              ))
            }
            <tr>
              <td>
                <button disabled={investors.length > 9} className="flex items-center gap-3" onClick={addInvestor}>
                  <img className="invert" src="/img/icons/plus.svg" alt="add" />
                  Add line
                </button>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>

          <colgroup>
            <col className="w-[30%]" />
            <col />
            <col className="w-[12%]" />
            <col />
            <col />
            <col />
            <col />
          </colgroup>
        </table>
        {
          isFilled && (
            <button className="success-btn w-9 shrink-0" onClick={goToNext}>
              <img src="/img/icons/arrow-right.svg" className='mx-auto' alt="arrow-right" />
            </button>
          )
        }
      </div>
    </>
  );
}

export default ConvertibleDebt;