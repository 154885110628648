import Modal from "../../UI/Modal";

const OptionPool = ({ ...props }) => {
  return (
    <>
      <Modal {...props} size='sm'>
        <div className="flex flex-col gap-3">
          <div>Option pool: </div>
          <div>
            An option pool is a specified number of shares of common stock reserved for issuance to service providers of the company pursuant to options and other equity incentives plans.
          </div>
          <div>
            The option pool size can expand or contract by action of a company’s board of directors (with stockholder approval generally also required for expansions of the pool), and typically expands in connection with venture financing transactions.
          </div>
          <div>
            Providing options to service providers is a way to attract talented employees in a startup because the holders of options can share in the growth in a company’s value if the company goes public or is acquired.
          </div>
        </div>
      </Modal>
    </>
  );
}

export default OptionPool;