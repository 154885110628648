import Modal from "../../UI/Modal";

const ConversionPriceCap = ({ ...props }) => {
  return (
    <>
      <Modal {...props} size='sm'>
        <div className="flex flex-col gap-3">
          <div>Conversion Price Cap:   </div>
          <div>
            A Conversion Price Cap is used to determine the maximum price per share at which the convertible note will convert into (generally) preferred stock at the time of the subsequent round of financing, regardless of the valuation agreed to by the company and new money. In essence, a conversion price cap provides early investors with a better price per share than later investors. Conversion caps may be included with a discount, or as a standalone term. Further, conversion caps can create big discounts and can greatly, yet unintentionally, affect stockholder ownership of a company.             </div>
          <div>
            Leave blank if n/a.
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ConversionPriceCap;