const questions = [
  {
    'slug': 'company_form',
    'que': 'What is the company’s form?',
    'input_type': 'radio',
    'options': [
      'LLC',
      'C Corporation',
      'S Corporation'
    ],
    'errOption': {
      'indexes': [0, 2],
      'errorMsg': 'The hypothetical model assumes the company\'s form is a C Corp.'
    }
  },
  {
    'slug': 'company_name',
    'que': 'What is the company’s name?',
    'input_type': 'text',
    'placeholder': 'Company name'
  },
  {
    'slug': 'founder_num',
    'que': 'How many founders does the company have?',
    'input_type': 'radio',
    'options': [
      '1',
      '2',
      '3',
      '4',
      '5 or more'
    ],
    'errOption': {
      'indexes': [0],
      'errorMsg': 'The hypothetical model assumes 2 or more founders exist.'
    }
  },
  {
    'slug': 'founder_num_stock',
    'que': 'How many founders will be issued <span class="term">common stock</span>?',
    'input_type': 'radio',
    'options': [
      '1',
      '2',
      '3',
      '4',
      '5 or more'
    ],
    'errOption': {
      'indexes': [0],
      'errorMsg': 'The hypothetical model assumes 2 or more founders will be granted shares of common stock.'
    }
  },
  {
    'slug': 'founder_names',
    'que': 'List the founders that will be issued common stock (maximum of 5):',
    'input_type': 'text_group',
    'placeholder': 'Full name',
    'limit': 'founder_num_stock',
  },
  {
    'slug': 'founder_shares',
    'que': 'How many shares of common stock will be issued to each founder?',
    'input_type': 'text_group',
    'placeholder': 'Number of shares',
    'label': 'founder_names',
    'limit': 'founder_num_stock',
  },
  {
    'slug': 'employee_exist',
    'que': 'Does the company have employees?',
    'input_type': 'radio',
    'options': [
      'Yes',
      'No'
    ],
    'skip': {
      'if': {
        'employee_exist': 'No',
      },
      'to': 11
    }
  },
  {
    'slug': 'employee_issued',
    'que': 'Will key employees be issued common stock?',
    'input_type': 'radio',
    'options': [
      'Yes',
      'No'
    ],
    'skip': {
      'if': {
        'employee_issued': 'No',
      },
      'to': 11
    }
  },
  {
    'slug': 'employee_num_stock',
    'que': 'How many key employees will be issued common stock?',
    'input_type': 'radio',
    'options': [
      '1',
      '2',
      '3',
      '4',
      '5 or more'
    ],
  },
  {
    'slug': 'employee_names',
    'que': 'List the key employees that will be issued common stock:',
    'input_type': 'text_group',
    'placeholder': 'Full name',
    'limit': 'employee_num_stock',
  },
  {
    'slug': 'employee_shares',
    'que': 'How many shares of fully vested common stock will be issued to each key employee?',
    'input_type': 'text_group',
    'placeholder': 'Number of shares',
    'label': 'employee_names',
    'limit': 'employee_num_stock',
  },
  {
    'slug': 'advisor_exist',
    'que': 'Does the company have advisors?',
    'input_type': 'radio',
    'options': [
      'Yes',
      'No'
    ],
    'skip': {
      'if': {
        'advisor_exist': 'No',
      },
      'to': 16
    }
  },
  {
    'slug': 'advisor_issued',
    'que': 'Will the advisors be issued common stock?',
    'input_type': 'radio',
    'options': [
      'Yes',
      'No'
    ],
    'skip': {
      'if': {
        'advisor_issued': 'No',
      },
      'to': 16
    }
  },
  {
    'slug': 'advisor_num_stock',
    'que': 'How many advisors will be issued common stock?',
    'input_type': 'radio',
    'options': [
      '1',
      '2',
      '3',
      '4',
      '5 or more'
    ],
  },
  {
    'slug': 'advisor_names',
    'que': 'List the advisors that will be issued common stock:',
    'input_type': 'text_group',
    'placeholder': 'Full name',
    'limit': 'advisor_num_stock'
  },
  {
    'slug': 'advisor_shares',
    'que': 'How many shares of fully vested common stock will be issued to each advisor?',
    'input_type': 'text_group',
    'label': 'advisor_names',
    'placeholder': 'Number of shares',
    'limit': 'advisor_num_stock'
  },
  {
    'slug': 'pool_exist',
    'que': 'Does a pre-investment common stock <span class="term">option pool</span> exist?',
    'input_type': 'radio',
    'options': [
      'Yes',
      'No'
    ],
    'skip': {
      'if': {
        'pool_exist': 'No',
      },
      'to': 20
    }
  },
  {
    'slug': 'option_pool',
    'que': 'Will the company issue additional shares of common stock or grant common options as part of the pre-investment option pool?',
    'input_type': 'radio',
    'options': [
      'Common stock',
      'Common options',
      'Both'
    ],
    'skip': {
      'if': {
        'option_pool': 'Common options',
      },
      'to': 19
    }
  },
  {
    'slug': 'common_stock',
    'que': 'Additional shares of common stock issued as part of the pre-investment option pool:',
    'input_type': 'text',
    'placeholder': 'Number of shares',
    'skip': {
      'if': {
        'option_pool': 'Common stock',
      },
      'to': 20
    }
  },
  {
    'slug': 'common_option',
    'que': 'Number of common options granted as part of the pre-investment option pool (1 common option = 1 share of common stock):',
    'input_type': 'text',
    'placeholder': 'Number of shares'
  },
];

export default questions;