import Modal from "../../UI/Modal";

const ConvertibleNotes = ({ ...props }) => {
  return (
    <Modal {...props} size='sm'>
      <div className="flex flex-col gap-3">
        <div>Convertible note:</div>
        <div>A convertible note functions similar to a traditional loan. The initial amount of cash invested into the company acts as the principal and accrues interest over a proscribed amount of time. </div>
        <div>Once the proscribed amount of time has passed ("maturity") or a particular event takes place, (e.g., a financing event), the aggregate sum of the principal and interest convert into equity - sometimes at a discount to the price of equity securities offered or subject to a cap on valuation.</div>
      </div>
    </Modal>
  );
}

export default ConvertibleNotes;