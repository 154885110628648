import Modal from "../../UI/Modal";

const PrincipalAmount = ({ ...props }) => {
  return (
    <>
      <Modal {...props} size='sm'>
        <div className="flex flex-col gap-3">
          <div>Principal Amount: </div>
          <div>
            Assumed to be the total investment amount on execution date of convertible debt instrument.
          </div>
        </div>
      </Modal>
    </>
  );
}

export default PrincipalAmount;