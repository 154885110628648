import { Link } from "react-router-dom";
import { useState } from "react";
import SidePanel from "../components/SidePanel";
import DisclaimerModal from "../components/DisclaimerModal";

const Disclaimer = () => {

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <div className="flex main sm:h-[90vh] tab-active">
        <SidePanel />
        <div className="w-full lg:w-[54vw] text-sm sm:text-lg py-4 sm:py-8 px-5 md:px-20 bg-white">
          <Link className="flex items-center gap-2" to="/services">
            <img src="/img/icons/arrow-left.svg" alt="back" />
            <div className="text-sm sm:text-lg text-gray-pri">Back to Services</div>
          </Link>
          <div className="pb-2 mt-4 text-lg font-bold text-center sm:mt-8 sm:text-xl text-success-pri border-bottom-gray-sec">
            Capitalization Table Evolution Generator
          </div>
          <div className="mt-2 text-center">Pre-investment through Series B</div>
          <div className="mt-6 sm:mt-10">
            The Capitalization Table Evolution Generator (“CTEG”) models various rounds of equity financing transactions from pre-investment through Series B. The interactive CTEG scenarios are intended to provide founders with visibility on potential company growth and stakeholder (e.g., founders, employees, advisors, and investors) value over time. CTEG is a modified version of a tool that Wright Squared uses internally.
          </div>
          <div className="mt-4 sm:mt-6">
            After the following series of questions, you will be directed to interactive tables based on the information submitted. In the interactive tables, there will be many instances where numbers or words are in green text. Numbers or words in green text are modifiable inputs. Please review these carefully and consult with an attorney about the consequences of your inputs.
          </div>
          <div className="flex flex-col items-center gap-5 mt-5 sm:gap-10 sm:mt-10">
            <div className="font-bold text-center">To continue, please read the disclaimer.</div>
            <button className="success-btn" onClick={() => { setModalOpen(true) }}>DISCLAIMER</button>
          </div>
        </div>
      </div>
      <DisclaimerModal open={modalOpen} setOpen={setModalOpen} />
    </>
  );

}

export default Disclaimer;