import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect, useRef } from 'react';
import { clearData } from '../../redux/dataSlice';
import { goToIndex } from '../../redux/progressSlice';
import questions from "./questions";
import Input from "../UI/Input";
import ResultTable from "./ResultTable";

const PreInvestment = ({ activeTab, onClick }) => {

  const activeIndex = useSelector((state) => state.progress.activeIndex);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const containerEl = useRef();
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);

  const goToCurrent = () => {
    window.dispatchEvent(new Event('go-to-current'));
  }

  const displayResultTable = () => {
    setIsSubmitted(true);
    containerEl.current.scrollTop = 0;
  }

  const restart = () => {
    setIsSubmitted(false);
    dispatch(goToIndex(0));
    dispatch(clearData());
  }

  useEffect(() => {
    if (activeIndex === questions.length && containerEl.current) {
      requestAnimationFrame(() => {
        containerEl.current.scrollTop = containerEl.current.scrollHeight;
      })
    } else if (activeIndex === 0) {
      setIsSubmitted(false);
    }
  }, [activeIndex]);

  useEffect(() => {
    setIsActive((activeTab === 'PreInvestment') ? true : false);
  }, [activeTab])

  return (
    <>
      {
        !isActive && (
          <div className="px-6 py-3 text-white bg-black border-bottom-gray-sec">
            <button className="flex items-center gap-2" onClick={onClick}>
              <div className="font-bold">PRE-INVESTMENT</div>
              <img src="/img/icons/plus.svg" alt="expand" />
            </button>
          </div>
        )
      }

      {
        isActive && (
          <div className={`flex flex-col overflow-hidden border-gray-sec ${isActive && 'grow'}`}>
            <div className="flex justify-between px-6 py-3">
              <div className="font-bold">PRE-INVESTMENT</div>
              {
                !isSubmitted ? (
                  <button className="flex gap-2 text-gray-pri" onClick={goToCurrent}>
                    <img src="/img/icons/arrow-back.svg" alt="back" />
                    Back to current question
                  </button>
                ) : (
                  <div>
                    <span className="font-bold text-success-pri">Green</span> <i>names and values are modifiable inputs.</i>
                  </div>
                )
              }

            </div>

            <div className="px-10 mt-4 overflow-auto" ref={containerEl}>
              {
                !isSubmitted ? (
                  <div className="px-10">
                    <ol className="text-lg list-decimal questions">
                      {
                        questions.map((question, key) => (
                          <Input index={key} key={key} {...question} />
                        ))
                      }
                    </ol>
                    <div className="flex flex-col items-center my-24">
                      <button className="success-btn w-52" onClick={displayResultTable} disabled={activeIndex < questions.length}>SUBMIT</button>
                      <div className="mt-5">Inputs will be modifiable.</div>
                    </div>
                  </div>
                ) : (
                  <ResultTable restart={restart} />
                )
              }
            </div>
          </div>
        )
      }
    </>
  );
}

export default PreInvestment;