import { useState, useEffect } from "react";
import "./style.scss";

const Modal = ({ children, open, setOpen, size = 'lg' }) => {
  const [show, setShow] = useState(open || false);

  useEffect(() => {
    setShow(open);
  }, [open]);

  const closeModal = () => {
    setShow(false);
    setOpen && setOpen(false);
  }

  return (
    <>
      {
        show && (
          <div className="absolute top-0 left-0 z-10 w-full h-full">
            <div className="absolute top-0 left-0 w-full h-full bg-[#A6A6A699]" />
            <div className={`modal-${size} max-w-[1100px] p-5 mx-auto flex h-full xl:items-center`}>
              <div className="relative w-full p-5 overflow-y-scroll bg-white modal-body md:px-16 md:py-9">
                <button className="absolute top-4 right-4" onClick={closeModal}>
                  <img className="w-3 h-3" src="/img/icons/xmark.svg" alt="close" />
                </button>
                {children}
              </div>
            </div>
          </div>
        )
      }
    </>
  )
}

export default Modal;