import { useEffect } from "react";

export const useTerms = () => {

  const handleClick = (event) => {
    const element = event.target;

    if (element.classList.contains('term')) {
      window.dispatchEvent(new CustomEvent('term-click', {
        detail: {
          term: element.innerHTML
        }
      }));
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);
}