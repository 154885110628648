import { useSelector, useDispatch } from 'react-redux';
import { goToIndex } from "@/redux/progressSlice";
import { updateData } from "@/redux/dataSlice";
import { useState, useEffect, useRef, useCallback } from 'react';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import customInputs from '../../CustomInputs';

const Input = ({ index, slug, que, input_type, options, max_num, placeholder, placeholders, label, errOption, skip, limit }) => {

  const activeIndex = useSelector((state) => state.progress.activeIndex);
  const formData = useSelector((state) => state.data.form);
  const activeTab = useSelector((state) => state.progress.activeTab);
  const dispatch = useDispatch();
  const fieldType = slug.includes('shares') ? 'number' : 'text';
  const [isFilled, setIsFilled] = useState(false);
  const liElement = useRef(null);
  const [filledValues, setFilledValues] = useState([]);
  const [maxNum, setMaxNum] = useState(max_num || 5);
  const [date, setDate] = useState();
  const CustomInput = customInputs[slug];

  const onInputChange = (index = 0) => (e) => {
    const value = e.target.value;
    const newFilledValues = [...filledValues];
    newFilledValues[index] = value;
    setFilledValues(newFilledValues);

    if (newFilledValues.filter(value => value).length === ((input_type === 'text' || input_type === 'date') ? 1 : maxNum)) {

      // Update Redux Storage
      dispatch(updateData({
        [slug]: newFilledValues
      }));

      setIsFilled(true);
    } else {
      setIsFilled(false);
    }
  }

  const goToNext = useCallback(
    (curVal) => {
      let targetIndex = index + 1;

      if (skip) {
        const [key, value] = Object.entries(skip.if)[0];
        if ((formData[key] === value) || (key === slug && value === curVal)) {
          targetIndex = skip.to;
        }
      }

      dispatch(goToIndex(targetIndex));
    },
    [dispatch, formData, skip, index, slug]
  );

  const onClickOption = (e, optionIndex) => {
    if (errOption && errOption.indexes.includes(optionIndex)) {
      e.preventDefault();
      const errorEvent = new CustomEvent("option-error", {
        detail: {
          error: errOption.errorMsg
        }
      });

      window.dispatchEvent(errorEvent);
    } else {
      const optionVal = options[optionIndex];

      // Update Redux Storage
      dispatch(updateData({
        [slug]: optionVal
      }));

      goToNext(optionVal);
    }
  }

  useEffect(() => {
    const scrollIntoView = (e, behavior = 'auto') => {
      if (activeIndex === index) {
        liElement.current.scrollIntoView({
          behavior
        });
      }
    }

    scrollIntoView(new Event('click'), 'smooth');

    window.addEventListener('go-to-current', scrollIntoView);

    return () => {
      window.removeEventListener('go-to-current', scrollIntoView);
    };

  }, [dispatch, activeIndex, index]);

  useEffect(() => {
    if (index === activeIndex) {
      liElement.current.scrollIntoView({
        behavior: 'auto'
      });
    }
  }, [activeTab])

  useEffect(() => {
    if (formData[limit]) {
      setMaxNum(parseInt(formData[limit]));
    }
  }, [formData, limit]);

  const onDateChange = (date) => {
    setDate(date);

    if (date) {
      setIsFilled(true);
      const dateString = moment(date).format('MM/DD/YYYY');

      dispatch(updateData({
        [slug]: dateString
      }));
    } else {
      setIsFilled(false);
    }
  }

  const onKeyDown = useCallback((e) => {
    if (isFilled && index === activeIndex && e.key === 'Enter') {
      goToNext();
    }
  }, [isFilled, index, activeIndex, goToNext]);

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown])

  return (
    <li ref={liElement} className={activeIndex === index ? 'active' : 'inactive'}>
      <div className="title" dangerouslySetInnerHTML={{ __html: que }}></div>
      {
        input_type === 'radio' && (
          <div className="radio-group">
            {
              options.map((option, key) => (
                <div key={key} onClick={(e) => onClickOption(e, key)}>
                  <input type="radio" id={slug + option} name={slug} />
                  <label className="ml-4" htmlFor={slug + option}>{option}</label>
                </div>
              ))
            }
          </div>
        )
      }
      {
        input_type === 'text' && (
          <div className="flex max-w-[900px]">
            <input type="text" name={slug} onChange={onInputChange()} placeholder={placeholder} />
            {
              (isFilled && index === activeIndex) && (
                <button className="success-btn w-9 shrink-0" onClick={goToNext}>
                  <img src="/img/icons/arrow-right.svg" className='mx-auto' alt="arrow-right" />
                </button>
              )
            }
          </div>
        )
      }
      {
        input_type === 'date' && (
          <div className="flex justify-between max-w-[900px] border-gray-sec">
            <DatePicker className='cteg-datepicker' placeholderText='MM/DD/YYYY' selected={date} onChange={onDateChange} />
            {
              (isFilled && index === activeIndex) && (
                <button className="success-btn w-9 shrink-0" onClick={goToNext}>
                  <img src="/img/icons/arrow-right.svg" className='mx-auto' alt="arrow-right" />
                </button>
              )
            }
          </div>
        )
      }
      {
        input_type === 'custom' && (
          <CustomInput index={index} />
        )
      }
      {
        input_type === 'text_group' && (
          <div className="input-group">
            {
              new Array(maxNum).fill(0).map((value, textIndex) => (
                <div key={textIndex}>
                  <div className="flex items-center gap-6 max-w-[900px]">
                    {
                      label && formData[label] && (
                        <div className="label">“{formData[label][textIndex]}”</div>
                      )
                    }
                    <div className="flex w-full">
                      <input type={fieldType} onChange={onInputChange(textIndex)} placeholder={placeholder || placeholders[textIndex]} />
                      {
                        (isFilled && textIndex === maxNum - 1 && index === activeIndex) && (
                          <button className="success-btn w-9 shrink-0" onClick={goToNext}>
                            <img src="/img/icons/arrow-right.svg" className='mx-auto' alt="arrow-right" />
                          </button>
                        )
                      }
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        )
      }
    </li>
  )
}

export default Input;