import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import professionalInfo from '../assets/json/ProfessionalInfo.json';
import SidePanel from '../components/SidePanel';

const Professional = () => {
  const { slug } = useParams();
  const info = professionalInfo.find((professional) =>
    professional.slug === slug
  );

  return (
    <>
      <div className="flex main h-[90vh] tab-active">
        <SidePanel />

        <div className="w-full lg:w-[54vw] bg-white pl-16 pr-8 py-4 sm:py-8 max-sm:px-5 relative">
          <Link className="flex items-center gap-2" to="/professionals">
            <img src="/img/icons/arrow-left.svg" alt="back" />
            <div className="text-sm sm:text-lg text-gray-pri">Back to Professionals</div>
          </Link>
          <div className="flex flex-col gap-8 mt-4 sm:mt-8 text-sm sm:text-lg h-[70vh] overflow-y-scroll">
            <div className="flex items-center gap-10">
              <img className="max-sm:w-24" src={`/img/headshot/${slug}.png`} alt={slug} />
              <div>
                <div className="text-2xl sm:text-4xl">{info.name}</div>
                <div
                  className="w-32 py-1 mt-2 text-sm font-semibold text-center uppercase sm:mt-6 sm:w-48 max-sm:text-xs bg-gray-thi text-gray-pri">
                  {info.role}</div>
              </div>
            </div>
            {Object.entries(info.mainInfo).map(([title, data], key) => (
              <div key={key}>
                <div className="font-bold">{title}</div>
                <ul className="mt-4 list-disc ml-7">
                  {
                    data.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))
                  }
                </ul>
              </div>
            ))}
            {info.slug === 'del' && (
              <div>
                <div className="font-bold">Bio</div>
                <div className="flex flex-col gap-5 mt-5">
                  <p>
                    Mr. Wright has extensive experience representing financial institutions in the development and use of new financial products, as well as advising investors, issuers, sponsors and investment banks in private placements of equity, debt and hybrid securities, restructurings, mergers and acquisitions, and joint ventures.
                  </p>
                  <p>
                    Currently, he serves as a full professor at the University of Missouri – Kansas City School of Law, where he teaches in the areas of tax, finance, business, securities, and blockchain & digital assets (crypto). Prior to joining the legal academy, he served as:
                  </p>
                  <ul className='flex flex-col gap-5 list-disc ml-7'>
                    <li>a federal prosecutor with the U.S. Department of Justice, focusing on tax, white collar, and organized crime, and secured convictions in all his cases;
                    </li>
                    <li>an attorney with Skadden, Arps, Slate, Meagher & Flom LLP, where he worked in both the banking and tax groups, as well as with other firms, where he focused on business and tax issues, including representing clients before the U.S. Tax Court, with amounts in controversy exceeding $1 billion;
                    </li>
                    <li>an investment banker, most recently with Bank of America, structuring derivatives.
                    </li>
                  </ul>
                  <p>In his free time, Mr. Wright studies Spanish, referees, and plays tennis and golf (when he is not injured). He is also an ardent supporter (culé) of the best fútbol team on the planet, F.C. Barçelona and a former reservist with the U.S. Marine Corp.
                  </p>
                </div>
              </div>
            )}
          </div>

          <Link to={info.linkedin}>
            <div
              className="absolute flex items-end gap-2 text-sm bottom-4 sm:bottom-8 right-8">
              <div className='text-gray-pri'>
                View on LinkedIn
              </div>
              <img src="/img/icons/linkedin-blue.svg" alt="linkedin" />
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Professional;