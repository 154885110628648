import ConvertibleDebt from "./ConvertibleDebt";
import SeriesAInvest from "./SeriesAInvest";
import SeriesBInvest from "./SeriesBInvest";

const customInputs = {
  'convertible_debt': ConvertibleDebt,
  'series_a_invest': SeriesAInvest,
  'series_b_invest': SeriesBInvest
};

export default customInputs;