const questions = [
  {
    'slug': 'convertible_notes',
    'que': 'Will the company issue convertible debt in the form of <span class="term">convertible notes</span>?',
    'input_type': 'radio',
    'options': [
      'Yes',
      'No',
    ],
    'errOption': {
      'indexes': [1],
      'errorMsg': 'This convertible notes calculator assumes the company will issue convertible debt in the form of convertible notes.'
    }
  },
  {
    'slug': 'convertible_debt_num',
    'que': 'How many convertible debt instruments will the company issue?',
    'input_type': 'radio',
    'options': [
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
    ],
  },
  {
    'slug': 'series_a_date',
    'que': 'Assumed date of Series A financing:',
    'input_type': 'date'
  },
  {
    'slug': 'convertible_debt',
    'que': 'Complete the following table for each respective Convertible Debt Holder and Convertible Debt Instrument (maximum of 10).',
    'input_type': 'custom'
  },
];

export default questions;