import Footer from "../components/Footer";
import PolicyModal from "../components/PolicyModal";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="main policy h-[90vh] pb-12">
        <PolicyModal type='terms' />
        <Footer />
      </div>
    </>
  );
}

export default PrivacyPolicy;