import { useSelector, useDispatch } from 'react-redux';
import { useRef } from 'react';
import { calcSum, calcPer, exportToPDF } from '@/utils';
import "@/assets/styles/table.scss";
import { updateData } from '../../redux/dataSlice';

const ResultTable = ({ restart }) => {

  const formData = useSelector((state) => state.data.form);
  const dispatch = useDispatch();
  const foStock = calcSum(formData.founder_shares);
  const employeeShares = formData.employee_shares || [];
  const advisorShares = formData.advisor_shares || [];
  const empStock = calcSum(employeeShares);
  const adStock = calcSum(advisorShares);
  const common_stock = parseInt(formData.common_stock) || 0;
  const common_option = parseInt(formData.common_option) || 0;
  const totalStock = foStock + empStock + adStock + common_stock;
  const total = totalStock + common_option;
  const printRef = useRef();

  const updateTableData = (dataKey, index) => (e) => {
    const target = e.target;
    const value = target.innerHTML || 0;

    // Calcuate the current offset of the cursor
    const node = target.firstChild;
    const range = window.getSelection().getRangeAt(0);
    const curOffset = range.endOffset;

    // Update Redux Storage
    const newData = [...formData[dataKey]];
    newData[index] = value
    dispatch(updateData({
      [dataKey]: newData
    }));

    // Set the cursor position back
    requestAnimationFrame(() => {
      if (node) {
        const range = document.createRange();
        const sel = window.getSelection();
        range.setStart(node, curOffset);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
      }
    });
  }

  const downloadPDF = () => {
    const fileName = 'PRE-INVESTMENT CAPITALIZATION TABLE';
    exportToPDF(printRef.current, fileName);
  }

  return (
    <>
      <div className="flex items-start justify-between">
        <div ref={printRef}>
          <div className="text-lg font-bold uppercase">“{formData.company_name}” - pre-investment capitalization table</div>

          <div className="mt-7">
            <div className="font-bold">Capitalization Table Prior to Investment</div>
            <div className="mt-2 table-container w-[776px]">
              <table className="striped">
                <thead>
                  <tr>
                    <th>Shareholders</th>
                    <th>Common Stock</th>
                    <th>Common Options</th>
                    <th>Fully Diluted (FD)</th>
                    <th>% FD</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    formData.founder_shares.map((stock, index) => (
                      <tr key={index}>
                        <td className="editable">{formData.founder_names[index]}</td>
                        <td className="editable" onInput={updateTableData('founder_shares', index)}>{stock}</td>
                        <td>-</td>
                        <td>{stock}</td>
                        <td>{calcPer(stock, total)}</td>
                      </tr>
                    ))
                  }
                  <tr>
                    <td>Other Employee(s)</td>
                    <td>{empStock}</td>
                    <td>-</td>
                    <td>{empStock}</td>
                    <td>{calcPer(empStock, total)}</td>
                  </tr>
                  {
                    advisorShares.map((stock, index) => (
                      <tr key={index}>
                        <td className="editable">{formData.advisor_names[index]}</td>
                        <td className="editable" onInput={updateTableData('advisor_shares', index)}>{stock}</td>
                        <td>-</td>
                        <td>{stock}</td>
                        <td>{calcPer(stock, total)}</td>
                      </tr>
                    ))
                  }
                  <tr>
                    <td>Pre-Investment Option Pool</td>
                    <td>{common_stock}</td>
                    <td>{common_option}</td>
                    <td>{common_stock + common_option}</td>
                    <td>{calcPer(common_stock + common_option, total)}</td>
                  </tr>
                  <tr className="total">
                    <td>TOTAL</td>
                    <td>{totalStock}</td>
                    <td>{common_option}</td>
                    <td>{total}</td>
                    <td>100%</td>
                  </tr>
                </tbody>

                <colgroup>
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                </colgroup>
              </table>
            </div>
          </div>

          {
            formData.employee_shares && (
              <div className="mt-10">
                <div className="font-bold">Other Employee Detail</div>
                <div className="mt-2 table-container w-[356px]">
                  <table className="striped">
                    <thead>
                      <tr>
                        <th>Key Employee</th>
                        <th>Common Stock</th>
                        <th>% FD</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        employeeShares.map((stock, index) => (
                          <tr>
                            <td className="editable">{formData.employee_names[index]}</td>
                            <td className="editable" onInput={updateTableData('employee_shares', index)}>{stock}</td>
                            <td>{calcPer(stock, total)}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                    <colgroup>
                      <col />
                      <col />
                      <col />
                    </colgroup>
                  </table>
                </div>
              </div>
            )
          }

          <div className="mt-10">
            <div className="font-bold">Cap Table Summary</div>
            <div className="mt-2 table-container w-[350px]">
              <table>
                <colgroup>
                  <col />
                  <col />
                  <col />
                </colgroup>
                <thead>
                  <tr>
                    <th>Shareholder</th>
                    <th>Fully Diluted (FD)</th>
                    <th>% FD</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Employees</td>
                    <td>{foStock + empStock}</td>
                    <td>{calcPer(foStock + empStock, total)}</td>
                  </tr>
                  <tr>
                    <td>Advisors</td>
                    <td>{adStock}</td>
                    <td>{calcPer(adStock, total)}</td>
                  </tr>
                  <tr>
                    <td>Option Pool</td>
                    <td>{common_stock + common_option}</td>
                    <td>{calcPer(common_stock + common_option, total)}</td>
                  </tr>
                  <tr className="total">
                    <td>TOTAL</td>
                    <td>{total}</td>
                    <td>100%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <button className="w-48 py-2 uppercase outline-btn" onClick={restart}>Back to questions</button>
      </div>

      <div className="mt-10 text-sm text-gray-pri">
        Disclaimer: Wright Squared does not assume any responsibility for any consequence resulting from your use of these documents. These documents have been prepared for informational purposes and is not intended to (a) constitute legal advice (b) create an attorney-client relationship (c) be advertising or a solicitation of any type. Each situation is highly fact specific and requires a knowledge of both state and federal laws and therefore any party should seek legal advice from a licensed attorney in the relevant jurisdictions.
      </div>
      <div className="flex items-center justify-between">
        <div className="flex gap-3 my-12">
          <img src="/img/icons/arrow-down.svg" alt="proceed" />
          <div className="text-lg font-bold">To proceed, expand “Convertible Notes” below.</div>
        </div>
        <div className="flex gap-5">
          <button className="w-48 outline-btn" onClick={restart}>BACK TO QUESTIONS</button>
          <button className="w-48 success-btn" onClick={downloadPDF}>DOWNLOAD PDF</button>
        </div>
      </div>
    </>
  );
}

export default ResultTable;