import Modal from "../../UI/Modal";

const PremoneyValuation = (props) => {
  return (
    <>
      <Modal {...props} size='sm'>
        <div className="flex flex-col gap-3">
          <div>Pre-money valuation: </div>
          <div>
            Pre-money valuation is the amount that an investor and the company agree the company is worth immediately prior to the round of financing.
          </div>
        </div>
      </Modal>
    </>
  );
}

export default PremoneyValuation;