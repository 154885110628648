import { useSelector, useDispatch } from 'react-redux';
import { useRef } from 'react';
import { calcSum, calcPer, exportToPDF, setOffsetBack } from '@/utils';
import "@/assets/styles/table.scss";
import { updateData } from '../../redux/dataSlice';

const ResultTable = ({ restart }) => {

  const formData = useSelector((state) => state.data.form);
  const investors = formData.investors;
  const dispatch = useDispatch();
  const printRef = useRef();
  const otherDebtHolders = investors.slice(3);
  const { series_a_lead_investor, series_a_other_investors, series_b_lead_investor, series_b_other_investors } = formData;

  const foStock = calcSum(formData.founder_shares);
  const employeeShares = formData.employee_shares || [];
  const advisorShares = formData.advisor_shares || [];
  const empStock = calcSum(employeeShares);
  const adStock = calcSum(advisorShares);
  const common_stock = parseInt(formData.common_stock) || 0;
  const common_option = parseInt(formData.common_option) || 0;
  const totalStock = foStock + empStock + adStock + common_stock + common_option;
  const totalComSecurities = totalStock + parseInt(formData.series_a_option_pool) + parseInt(formData.series_b_option_pool);
  const seriesAPrice = (formData.premoney_valuation / totalComSecurities).toFixed(2);
  const seriesAIssueTotal = calcSum([calcSum(investors.map(investor => investor.seriesAIssueValNew)), series_a_lead_investor[1], series_a_other_investors]);
  const seriesAShareTotal = (seriesAIssueTotal / seriesAPrice).toFixed(2);
  const sharesPrior = calcSum([totalComSecurities, seriesAShareTotal]);
  const seriesAPostMoney = parseInt(sharesPrior * seriesAPrice);
  const beforeSeriesBShareTotal = sharesPrior - parseInt(formData.series_b_option_pool);
  const seriesBPrice = (sharesPrior / formData.premoney_valuation_series_b).toFixed(2);
  const seriesBIssueTotal = calcSum([calcSum(investors.map(investor => investor.series_b)), series_a_lead_investor[2], series_a_other_investors[1], series_b_lead_investor[1], series_b_other_investors]);
  const seriesBShareTotal = parseInt(seriesBIssueTotal / seriesBPrice);
  const totalSecurities = calcSum([sharesPrior, seriesBShareTotal]);

  const updateTableData = (dataKey, index) => (e) => {
    const target = e.target;
    const value = target.innerHTML || 0;

    // Update Redux Storage
    let newData;
    if (index) {
      newData = [...formData[dataKey]];
      newData[index] = value
    } else {
      newData = value;
    }

    dispatch(updateData({
      [dataKey]: newData
    }));

    setOffsetBack(target);
  }

  const downloadPDF = () => {
    const fileName = 'Series B Capitalization Table';
    exportToPDF(printRef.current, fileName);
  }

  return (
    <>
      <div className="flex items-start justify-between">
        <div className='w-full'>
          <div className="flex justify-between">
            <div className="text-lg font-bold uppercase">“{formData.company_name}” - Series B Capitalization Table</div>
          </div>
          <div className="mt-7" >
            <div className="font-bold">Series B Inputs</div>
            <div className="table-container w-[620px]">
              <table className="striped">
                <tbody>
                  <tr>
                    <td>Series A Post-Money Valuation</td>
                    <td>${seriesAPostMoney}</td>
                  </tr>
                  <tr>
                    <td>Valuation Step-Up</td>
                    <td>{calcPer(formData.premoney_valuation_series_b - seriesAPostMoney, seriesAPostMoney)}</td>
                  </tr>
                  <tr>
                    <td>Series B Pre-Money Valuation</td>
                    <td className='editable' onInput={updateTableData('premoney_valuation_series_b')}>{formData.premoney_valuation_series_b}</td>
                  </tr>
                  <tr>
                    <td>Option Pool Expansion (prior to Series B share price calculation)</td>
                    <td className='editable' onInput={updateTableData('series_b_option_pool')}>{formData.series_b_option_pool}</td>
                  </tr>
                  <tr>
                    <td>Option Pool % of Fully Diluted after Financing  </td>
                    <td>{calcPer(calcSum([formData.series_a_option_pool, formData.series_b_option_pool]), totalSecurities)}</td>
                  </tr>
                  <tr>
                    <td>Shares Prior to B Round</td>
                    <td>{sharesPrior}</td>
                  </tr>
                  <tr>
                    <td>Share Price</td>
                    <td>{seriesBPrice}</td>
                  </tr>
                </tbody>
                <colgroup>
                  <col className="border-r-0" />
                  <col className="border-l-0" />
                </colgroup>
              </table>
              <table>
                <thead>
                  <th>Investor</th>
                  <th>New $</th>
                </thead>
                <tbody>
                  {
                    investors.slice(0, 3).map((investor, index) => {
                      return (
                        <tr key={index}>
                          <td>{investor.fullname}</td>
                          <td>{investor.series_b}</td>
                        </tr>
                      )
                    })
                  }
                  {
                    <tr>
                      <td>Other Convertible Debt Holder(s)</td>
                      <td>
                        {calcSum(otherDebtHolders.map(investor => investor.series_b))}
                      </td>
                    </tr>
                  }
                  <tr>
                    <td>{series_a_lead_investor[0]}</td>
                    <td>{series_a_lead_investor[2]}</td>
                  </tr>
                  <tr>
                    <td>Series A Other Investor(s)</td>
                    <td>{series_a_other_investors[1]}</td>
                  </tr>
                  <tr>
                    <td className='editable'>{series_b_lead_investor[0]}</td>
                    <td className='editable' onInput={updateTableData('series_b_lead_investor', 1)}>{series_b_lead_investor[1]}</td>
                  </tr>
                  <tr>
                    <td>Series B Other Investor(s)</td>
                    <td className='editable' onInput={updateTableData('series_b_other_investors')}>{series_b_other_investors}</td>
                  </tr>
                  <tr className="total">
                    <td>
                      Total Series B Value & Cash
                    </td>
                    <td>
                      $ {calcSum([calcSum(investors.map(investor => investor.series_b)), series_a_lead_investor[2], series_a_other_investors[1], series_b_lead_investor[1], series_b_other_investors])}
                    </td>
                  </tr>
                  <tr className='total'>
                    <td>Post Money Valuation</td>
                    <td>${parseInt(totalSecurities * seriesBPrice)}</td>
                  </tr>
                </tbody>
                <colgroup>
                  <col />
                  <col />
                </colgroup>
              </table>
            </div>
          </div>
          <div className="mt-10 overflow-x-auto">
            <div className="font-bold">Detailed Series A Capitalization Table</div>
            <div className="table-container w-[1500px] mt-2" ref={printRef}>
              <table className='lg-table'>
                <thead>
                  <tr className='relative z-50 text-center bg-white'>
                    <th rowSpan={2} className='pt-20'>Shareholders</th>
                    <th colSpan={4}>Common</th>
                    <th colSpan={2}>Series A</th>
                    <th colSpan={3}>Series B</th>
                    <th colSpan={2}>Total Securities</th>
                  </tr>
                  <tr className='text-center'>
                    <th className='!border-l-0'>Common Stock</th>
                    <th>Common Options</th>
                    <th>Total Common Securities</th>
                    <th>% Common Securities</th>
                    <th>Series A Shares</th>
                    <th>% of Series A</th>
                    <th>
                      Series B Investment $
                    </th>
                    <th>Series B Shares</th>
                    <th>% of Series B</th>
                    <th>Total Securities</th>
                    <th>% Fully Diluted</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    formData.founder_shares.map((stock, index) => (
                      <tr key={index}>
                        <td>{formData.founder_names[index]}</td>
                        <td>{stock}</td>
                        <td>-</td>
                        <td>{stock}</td>
                        <td>{calcPer(stock, totalComSecurities)}</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>{stock}</td>
                        <td>{calcPer(stock, totalSecurities)}</td>
                      </tr>
                    ))
                  }
                  <tr>
                    <td>Other Employee(s)</td>
                    <td>{empStock}</td>
                    <td>-</td>
                    <td>{empStock}</td>
                    <td>{calcPer(empStock, totalComSecurities)}</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>{empStock}</td>
                    <td>{calcPer(empStock, totalSecurities)}</td>
                  </tr>
                  {
                    advisorShares.map((stock, index) => (
                      <tr key={index}>
                        <td>{formData.advisor_names[index]}</td>
                        <td>{stock}</td>
                        <td>-</td>
                        <td>{stock}</td>
                        <td>{calcPer(stock, totalComSecurities)}</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>{stock}</td>
                        <td>{calcPer(stock, totalSecurities)}</td>
                      </tr>
                    ))
                  }
                  <tr>
                    <td>Pre-Investment Option Pool</td>
                    <td>{common_stock}</td>
                    <td>{common_option}</td>
                    <td>{common_stock + common_option}</td>
                    <td>{calcPer(common_stock + common_option, totalComSecurities)}</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>{common_stock + common_option}</td>
                    <td>{calcPer(common_stock + common_option, totalSecurities)}</td>
                  </tr>
                  <tr>
                    <td>Series A Option Pool Expansion</td>
                    <td>-</td>
                    <td>{formData.series_a_option_pool}</td>
                    <td>{formData.series_a_option_pool}</td>
                    <td>{calcPer(formData.series_a_option_pool, totalComSecurities)}</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>{formData.series_a_option_pool}</td>
                    <td>{calcPer(formData.series_a_option_pool, totalSecurities)}</td>
                  </tr>
                  <tr>
                    <td>Series B Option Pool Expansion</td>
                    <td>-</td>
                    <td>{formData.series_b_option_pool}</td>
                    <td>{formData.series_b_option_pool}</td>
                    <td>{calcPer(formData.series_b_option_pool, totalComSecurities)}</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>{formData.series_b_option_pool}</td>
                    <td>{calcPer(formData.series_b_option_pool, totalSecurities)}</td>
                  </tr>
                  {
                    investors.slice(0, 3).map((investor, index) => {
                      const seriesAShares = parseInt(investor.seriesAIssueValNew / seriesAPrice);
                      const seriesBShares = parseInt(investor.series_b / seriesBPrice);
                      return (
                        <tr key={index}>
                          <td>{investor.fullname}</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>{seriesAShares}</td>
                          <td>{calcPer(seriesAShares, seriesAShareTotal)}</td>
                          <td>{investor.series_b}</td>
                          <td>{seriesBShares}</td>
                          <td>{calcPer(investor.series_b, seriesBIssueTotal)}</td>
                          <td>{seriesAShares + seriesBShares}</td>
                          <td>{calcPer(seriesAShares + seriesBShares, totalSecurities)}</td>
                        </tr>
                      );
                    })
                  }
                  {
                    [0].map(() => {
                      const seriesAIssueVal = parseInt(calcSum(otherDebtHolders.map(investor => investor.seriesAIssueValNew)));
                      const seriesBIssueVal = parseInt(calcSum(otherDebtHolders.map(investor => investor.series_b)));
                      const seriesAShares = parseInt(seriesAIssueVal / seriesAPrice);
                      const seriesBShares = parseInt(seriesBIssueVal / seriesBPrice);

                      return (
                        <tr>
                          <td>Other Convertible Debt Holder(s)</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>{seriesAShares}</td>
                          <td>{calcPer(seriesAShares, seriesAShareTotal)}</td>
                          <td>{seriesBIssueVal}</td>
                          <td>{seriesBShares}</td>
                          <td>{calcPer(seriesBShares, seriesBShareTotal)}</td>
                          <td>{seriesAShares + seriesBShares}</td>
                          <td>{calcPer(seriesAShares + seriesBShares, totalSecurities)}</td>
                        </tr>
                      );
                    })
                  }
                  <tr>
                    <td>{formData.series_a_lead_investor[0]}</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>{parseInt(series_a_lead_investor[1] / seriesAPrice)}</td>
                    <td>{calcPer((series_a_lead_investor[1] / seriesAPrice), seriesAShareTotal)}</td>
                    <td>{series_a_lead_investor[2]}</td>
                    <td>{parseInt(series_a_lead_investor[2] / seriesBPrice)}</td>
                    <td>{calcPer((series_a_lead_investor[2] / seriesBPrice), seriesBShareTotal)}</td>
                    <td>{parseInt(series_a_lead_investor[1] / seriesAPrice + series_a_lead_investor[2] / seriesBPrice)}</td>
                    <td>{calcPer(series_a_lead_investor[1] / seriesAPrice + series_a_lead_investor[2] / seriesBPrice, totalSecurities)}</td>
                  </tr>
                  <tr>
                    <td>Series A Other Investor(s)</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>{parseInt(series_a_other_investors[0] / seriesAPrice)}</td>
                    <td>{calcPer((series_a_other_investors[0] / seriesAPrice), seriesAShareTotal)}</td>
                    <td>{series_a_other_investors[1]}</td>
                    <td>{parseInt(series_a_other_investors[1] / seriesBPrice)}</td>
                    <td>{calcPer(series_a_other_investors[1] / seriesBPrice, seriesBShareTotal)}</td>
                    <td>{parseInt(series_a_other_investors[0] / seriesAPrice + series_a_other_investors[1] / seriesBPrice)}</td>
                    <td>{calcPer(series_a_other_investors[0] / seriesAPrice + series_a_other_investors[1] / seriesBPrice, totalSecurities)}</td>
                  </tr>
                  <tr>
                    <td>{formData.series_b_lead_investor[0]}</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>_</td>
                    <td>0.0 %</td>
                    <td>{series_b_lead_investor[1]}</td>
                    <td>{parseInt(series_b_lead_investor[1] / seriesBPrice)}</td>
                    <td>{calcPer((series_b_lead_investor[1] / seriesBPrice), seriesBShareTotal)}</td>
                    <td>{parseInt(series_b_lead_investor[1] / seriesBPrice)}</td>
                    <td>{calcPer(series_b_lead_investor[1] / seriesBPrice, totalSecurities)}</td>
                  </tr>
                  <tr>
                    <td>Series B Other Investor(s)</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>_</td>
                    <td>0.0 %</td>
                    <td>{series_b_other_investors}</td>
                    <td>{parseInt(series_b_other_investors / seriesBPrice)}</td>
                    <td>{calcPer(series_b_other_investors / seriesBPrice, seriesBShareTotal)}</td>
                    <td>{parseInt(series_b_other_investors / seriesBPrice)}</td>
                    <td>{calcPer(series_b_other_investors / seriesBPrice, totalSecurities)}</td>
                  </tr>
                  <tr className="total">
                    <td>Total</td>
                    <td>{totalStock}</td>
                    <td>{calcSum([formData.series_a_option_pool, formData.series_b_option_pool])}</td>
                    <td>{totalComSecurities}</td>
                    <td>100%</td>
                    <td>{parseInt(seriesAShareTotal)}</td>
                    <td>100%</td>
                    <td>{seriesBIssueTotal}</td>
                    <td>{parseInt(seriesBShareTotal)}</td>
                    <td>100%</td>
                    <td>{parseInt(totalSecurities)}</td>
                    <td>100%</td>
                  </tr>
                </tbody>
                <colgroup>
                  <col className='w-[270px]' />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                </colgroup>
              </table>
            </div>
          </div>

          <div className="mt-10">
            <div className="font-bold">Cap Table Summary</div>
            <div className="table-container w-[840px]">
              <table>
                <thead>
                  <tr className='text-center'>
                    <th rowSpan={2} className='pt-28'>Shareholders</th>
                    <th colSpan={2}>Pre-Investment & Option Pool Expansion</th>
                    <th colSpan={2}>Post-Series A, Pre-Series B, & Option Pool Expansion</th>
                    <th colSpan={2}>Post-Series B</th>
                  </tr>
                  <tr className='text-center'>
                    <th>Fully Diluted</th>
                    <th>% FD</th>
                    <th>Fully Diluted</th>
                    <th>% FD</th>
                    <th>Fully Diluted</th>
                    <th>% FD</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Employees</td>
                    <td>{foStock + empStock}</td>
                    <td>{calcPer(foStock + empStock, totalStock)}</td>
                    <td>{foStock + empStock}</td>
                    <td>{calcPer(foStock + empStock, beforeSeriesBShareTotal)}</td>
                    <td>{foStock + empStock}</td>
                    <td>{calcPer(foStock + empStock, totalSecurities)}</td>
                  </tr>
                  <tr>
                    <td>Advisors</td>
                    <td>{adStock}</td>
                    <td>{calcPer(adStock, totalStock)}</td>
                    <td>{adStock}</td>
                    <td>{calcPer(adStock, beforeSeriesBShareTotal)}</td>
                    <td>{adStock}</td>
                    <td>{calcPer(adStock, totalSecurities)}</td>
                  </tr>
                  <tr>
                    <td>Option Pool</td>
                    <td>{common_stock + common_option}</td>
                    <td>{calcPer(common_stock + common_option, totalStock)}</td>
                    <td>{common_stock + common_option + parseInt(formData.series_a_option_pool)}</td>
                    <td>{calcPer(common_stock + common_option + parseInt(formData.series_a_option_pool), beforeSeriesBShareTotal)}</td>
                    <td>{common_stock + common_option + parseInt(formData.series_a_option_pool)}</td>
                    <td>{calcPer(common_stock + common_option + parseInt(formData.series_a_option_pool), totalSecurities)}</td>
                  </tr>
                  <tr>
                    <td>Pre-Series A Investors</td>
                    <td>-</td>
                    <td>-</td>
                    <td>{parseInt(calcSum(investors.map(investor => parseInt(investor.seriesAIssueVal) + parseInt(investor.series_a))) / seriesAPrice)}</td>
                    <td>{calcPer(calcSum(investors.map(investor => parseInt(investor.seriesAIssueVal) + parseInt(investor.series_a))) / seriesAPrice, beforeSeriesBShareTotal)}</td>
                    <td>{parseInt(calcSum(investors.map(investor => parseInt(investor.seriesAIssueVal) + parseInt(investor.series_a))) / seriesAPrice)}</td>
                    <td>{calcPer(calcSum(investors.map(investor => parseInt(investor.seriesAIssueVal) + parseInt(investor.series_a))) / seriesAPrice, totalSecurities)}</td>
                  </tr>
                  <tr>
                    <td>Series A Investors</td>
                    <td>-</td>
                    <td>-</td>
                    <td>{parseInt(calcSum([series_a_lead_investor[1], series_a_other_investors[0]]) / seriesAPrice)}</td>
                    <td>{calcPer(calcSum([series_a_lead_investor[1], series_a_other_investors[0]]) / seriesAPrice, beforeSeriesBShareTotal)}</td>
                    <td>{parseInt(calcSum([series_a_lead_investor[1], series_a_other_investors[0]]) / seriesAPrice + calcSum([series_a_lead_investor[2], series_a_other_investors[1]]) / seriesBPrice)}</td>
                    <td>{calcPer(calcSum([series_a_lead_investor[1], series_a_other_investors]) / seriesAPrice, totalSecurities)}</td>
                  </tr>
                  <tr>
                    <td>Series B Investors</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>{parseInt(calcSum([series_b_lead_investor[1], series_b_other_investors]) / seriesBPrice)}</td>
                    <td>{calcPer(calcSum([series_b_lead_investor[1], series_b_other_investors]) / seriesBPrice, totalSecurities)}</td>
                  </tr>
                  <tr className="total">
                    <td>Total</td>
                    <td>{totalStock}</td>
                    <td>100%</td>
                    <td>{beforeSeriesBShareTotal}</td>
                    <td>100%</td>
                    <td>{totalSecurities}</td>
                    <td>100%</td>
                  </tr>
                </tbody>
                <colgroup>
                  <col className='w-[25%]' />
                  <col className='w-[14%]' />
                  <col className='w-[12%]' />
                  <col className='w-[14%]' />
                  <col className='w-[12%]' />
                  <col className='w-[14%]' />
                  <col className='w-[12%]' />
                </colgroup>
              </table>
            </div>
          </div>
        </div>
      </div >

      <div className="mt-10 text-sm text-gray-pri">
        Disclaimer: Wright Squared does not assume any responsibility for any consequence resulting from your use of these documents. These documents have been prepared for informational purposes and is not intended to (a) constitute legal advice (b) create an attorney-client relationship (c) be advertising or a solicitation of any type. Each situation is highly fact specific and requires a knowledge of both state and federal laws and therefore any party should seek legal advice from a licensed attorney in the relevant jurisdictions.
      </div>
      <div className="flex items-center justify-between">
        <div className="flex gap-3 my-12">
          <img src="/img/icons/arrow-down.svg" alt="proceed" />
          <div className="text-lg font-bold">To proceed, expand “Growth” below.</div>
        </div>
        <div className="flex gap-5">
          <button className="w-48 outline-btn" onClick={restart}>BACK TO QUESTIONS</button>
          <button className="w-48 success-btn" onClick={downloadPDF}>DOWNLOAD PDF</button>
        </div>
      </div>
    </>
  );
}

export default ResultTable;