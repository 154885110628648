import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  curStep: 'PreInvestment',
  activeTab: 'PreInvestment',
  activeIndex: 0,
};

export const progressSlice = createSlice({
  name: 'progress',
  initialState,
  reducers: {
    goToIndex: (state, action) => {
      const index = action.payload;
      state.activeIndex = index;

      if (index > 38) {
        state.curStep = 'Growth';
      } else if (index > 30) {
        state.curStep = 'SeriesB';
      } else if (index > 23) {
        state.curStep = 'SeriesA';
      } else if (index > 19) {
        state.curStep = 'ConvertibleNotes';
      } else {
        state.curStep = 'PreInvestment';
      }
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
  },
});

export const { goToIndex, setActiveTab } = progressSlice.actions;

export default progressSlice.reducer;