import { useSelector, useDispatch } from 'react-redux';
import { useRef, useEffect } from 'react';
import { calcSum, calcPer, calcAver, calcMin, exportToPDF, setOffsetBack } from '@/utils';
import "@/assets/styles/table.scss";
import { updateData } from '../../redux/dataSlice';
import moment from 'moment';

const ResultTable = ({ restart }) => {

  const formData = useSelector((state) => state.data.form);
  const investors = formData.investors;
  const dispatch = useDispatch();
  const printRef = useRef();
  const otherDebtHolders = investors.slice(3);
  const { series_a_lead_investor, series_a_other_investors } = formData;

  const foStock = calcSum(formData.founder_shares);
  const employeeShares = formData.employee_shares || [];
  const advisorShares = formData.advisor_shares || [];
  const empStock = calcSum(employeeShares);
  const adStock = calcSum(advisorShares);
  const common_stock = parseInt(formData.common_stock) || 0;
  const common_option = parseInt(formData.common_option) || 0;
  const totalStock = foStock + empStock + adStock + common_stock + common_option;
  const totalComSecurities = totalStock + parseInt(formData.series_a_option_pool);
  const seriesAPrice = (formData.premoney_valuation / totalComSecurities).toFixed(2);
  const seriesAActualTotal = calcSum([calcSum(investors.map(investor => investor.principal)), series_a_lead_investor[1], series_a_other_investors]);
  const seriesAIssueTotal = calcSum([calcSum(investors.map(investor => investor.seriesAIssueValNew)), series_a_lead_investor[1], series_a_other_investors]);
  const seriesAShareTotal = (seriesAIssueTotal / seriesAPrice).toFixed(2);
  const totalSecurities = calcSum([totalStock, seriesAShareTotal, formData.series_a_option_pool]);

  const updateTableData = (dataKey, index) => (e) => {
    const target = e.target;
    const value = target.innerHTML || 0;

    // Update Redux Storage
    let newData;
    if (index) {
      newData = [...formData[dataKey]];
      newData[index] = value
    } else {
      newData = value;
    }

    dispatch(updateData({
      [dataKey]: newData
    }));

    setOffsetBack(target);
  }

  const daysHeld = (investor) => {
    const date = moment(investor?.date_issue, 'MM/DD/YYYY');
    const series_a_date = moment(formData.series_a_date, 'MM/DD/YYYY');
    const diffInDays = series_a_date.diff(date, 'days');

    return diffInDays || 0;
  }

  const interestAcc = (investor) => {
    const totalDays = daysHeld(investor);

    return parseInt(investor.principal * investor.interest / 100 * totalDays / 365);
  }

  const totalAcc = (investor) => {
    return parseInt(investor.principal) + interestAcc(investor);
  }

  useEffect(() => {
    const newInvestors = JSON.parse(JSON.stringify(investors));

    newInvestors.map(investor => {
      const { issueVal } = getSeriesADetails(totalAcc(investor), investor.discount, investor.priceCap);
      investor.seriesAIssueVal = issueVal;
      investor.seriesAIssueValNew = parseInt(issueVal) + parseInt(investor.series_a);

      return investor;
    });

    dispatch(updateData({
      investors: newInvestors
    }));
  }, []);

  const getSeriesADetails = (principal, discount, priceCap) => {
    const convPriceDiscount = seriesAPrice * (1 - discount / 100);
    const convCap = priceCap ? (priceCap / totalComSecurities) : 'n/a';
    const minCap = calcMin([seriesAPrice, convPriceDiscount, convCap === 'n/a' ? Infinity : convCap]);
    const shares = (parseFloat(principal) / minCap).toFixed(0);
    const issueVal = (shares * seriesAPrice).toFixed(2);
    const effDiscount = convCap === 'n/a' ? convCap : calcPer(calcMin([convPriceDiscount, convCap]), seriesAPrice);

    return {
      convPriceDiscount,
      convCap,
      shares,
      issueVal,
      effDiscount
    };
  }

  const downloadPDF = () => {
    const fileName = 'Series A Capitalization Table';
    exportToPDF(printRef.current, fileName);
  }

  return (
    <>
      <div className="flex items-start justify-between">
        <div className='w-full'>
          <div className="flex justify-between">
            <div className="text-lg font-bold uppercase">“{formData.company_name}” - Series A Capitalization Table</div>
          </div>
          <div className="mt-7" >
            <div className="font-bold">Series A Inputs</div>
            <div className="table-container w-[650px]">
              <table className="striped">
                <tbody>
                  <tr>
                    <td>Date of Series A Financing</td>
                    <td className="editable" onInput={updateTableData('series_a_date')}>{formData.series_a_date}</td>
                  </tr>
                  <tr>
                    <td>Pre-Money Valuation</td>
                    <td className="editable" onInput={updateTableData('premoney_valuation')}>{formData.premoney_valuation}</td>
                  </tr>
                  <tr>
                    <td>Option Pool Expansion (prior to Series A share price calculation)</td>
                    <td className="editable" onInput={updateTableData('series_a_option_pool')}>{formData.series_a_option_pool}</td>
                  </tr>
                  <tr>
                    <td>Option Pool % of Fully Diluted after Financing</td>
                    <td>{calcPer(common_stock + common_option + parseInt(formData.series_a_option_pool), totalSecurities)}</td>
                  </tr>
                  <tr>
                    <td>Shares Prior to A Round</td>
                    <td>{totalComSecurities}</td>
                  </tr>
                  <tr>
                    <td>Share Price</td>
                    <td>{seriesAPrice}</td>
                  </tr>
                </tbody>
                <colgroup>
                  <col className="border-r-0" />
                  <col className="border-l-0" />
                </colgroup>
              </table>
              <table className='striped'>
                <thead>
                  <tr>
                    <th>Investor</th>
                    <th>Conv. Note</th>
                    <th>New $</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    investors.map((investor, index) => (
                      <tr kye={index}>
                        <td>{investor.fullname}</td>
                        <td>$ {investor.principal}</td>
                        <td>$ {investor.series_a}</td>
                        <td>$ {calcSum([investor.principal, investor.series_a])}</td>
                      </tr>
                    ))
                  }
                  <tr className="total">
                    <td>
                      Total Series A Cash
                    </td>
                    <td>
                      $ {calcSum(investors.map(investor => investor.principal))}
                    </td>
                    <td>
                      $ {calcSum(investors.map(investor => investor.series_a))}
                    </td>
                    <td>
                      $ {calcSum(investors.map(investor => calcSum([investor.principal, investor.series_a])))}
                    </td>
                  </tr>
                </tbody>
                <colgroup>
                  <col className='w-[42%]' />
                  <col className='w-[18%]' />
                  <col className='w-[20%]' />
                  <col className='w-[20%]' />
                </colgroup>
              </table>
              <table>
                <thead>
                  <tr>
                    <th>Investor</th>
                    <th>Conv. Note</th>
                    <th>New $</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    investors.slice(0, 3).map((investor, index) => {
                      return (
                        <tr key={index}>
                          <td>{investor.fullname}</td>
                          <td>$ {parseInt(investor.seriesAIssueVal)}</td>
                          <td>$ {investor.series_a}</td>
                          <td>$ {calcSum([investor.seriesAIssueVal, investor.series_a])}</td>
                        </tr>
                      )
                    })
                  }
                  {
                    <tr>
                      <td>Other Convertible Debt Holder(s)</td>
                      <td>{calcSum(otherDebtHolders.map(investor => investor.seriesAIssueVal))}</td>
                      <td>
                        {calcSum(otherDebtHolders.map(investor => investor.series_a))}
                      </td>
                      <td>
                        {calcSum(otherDebtHolders.map(investor => calcSum([investor.seriesAIssueVal, investor.series_a])))}
                      </td>
                    </tr>
                  }
                  <tr>
                    <td className='editable'>{series_a_lead_investor[0]}</td>
                    <td>0</td>
                    <td className='editable' onInput={updateTableData('series_a_lead_investor', 1)}>{series_a_lead_investor[1]}</td>
                    <td>$ {series_a_lead_investor[1]}</td>
                  </tr>
                  <tr>
                    <td>Series A Other Investor(s)</td>
                    <td>0</td>
                    <td className='editable' onInput={updateTableData('series_a_other_investors')}>{series_a_other_investors}</td>
                    <td>$ {series_a_other_investors}</td>
                  </tr>
                  <tr className="total">
                    <td>
                      Total Series A Value
                    </td>
                    <td>
                      $ {calcSum(investors.map(investor => investor.seriesAIssueVal))}
                    </td>
                    <td>
                      $ {calcSum([calcSum(investors.map(investor => investor.series_a)), formData.series_a_lead_investor[1], formData.series_a_other_investors])}
                    </td>
                    <td>
                      $ {calcSum([calcSum(investors.map(investor => calcSum([investor.seriesAIssueVal, investor.series_a]))), formData.series_a_lead_investor[1], formData.series_a_other_investors])}
                    </td>
                  </tr>
                  <tr className='total'>
                    <td>Post Money Valuation</td>
                    <td>_</td>
                    <td>_</td>
                    <td>${parseInt(totalSecurities * seriesAPrice)}</td>
                  </tr>
                </tbody>
                <colgroup>
                  <col className='w-[42%]' />
                  <col className='w-[18%]' />
                  <col className='w-[20%]' />
                  <col className='w-[20%]' />
                </colgroup>
              </table>
            </div>
          </div>
          <div className="mt-10 relative">
            <div className="font-bold">Note Conversion</div>
            <div className='overflow-x-auto'>
              <div className="table-container w-[1500px] relative">
                <table className='striped lg-table'>
                  <thead className='text-center'>
                    <tr className='text-center'>
                      <th>
                        Pre-Series A Investors
                      </th>
                      <th>
                        Principal &
                        Interest
                      </th>
                      <th>
                        Conversion
                        Discount
                      </th>
                      <th>
                        Conversion
                        Cap
                      </th>
                      <th>Series A Price</th>
                      <th>Conv. Price at Discount</th>
                      <th>Conv. Price at Cap</th>
                      <th>Series A Shares</th>
                      <th>Srs A Issue Value</th>
                      <th>Effective Discount</th>
                      <th>Srs A Value to Cash Invested</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      investors.slice(0, 3).map((investor, index) => {
                        const { convPriceDiscount, convCap, shares, issueVal, effDiscount } = getSeriesADetails(totalAcc(investor), investor.discount, investor.priceCap);

                        return (
                          <tr key={index}>
                            <td>{investor.fullname}</td>
                            <td>$ {totalAcc(investor)}</td>
                            <td>{investor.discount} %</td>
                            <td>$ {investor.price_cap}</td>
                            <td>$ {seriesAPrice}</td>
                            <td>$ {convPriceDiscount.toFixed(2)}</td>
                            <td>{convCap !== 'n/a' ? `$ ${convCap.toFixed(2)}` : convCap} </td>
                            <td>{shares}</td>
                            <td>$ {parseInt(issueVal)}</td>
                            <td>{effDiscount}</td>
                            <td>{(issueVal / parseFloat(investor.principal)).toFixed(2)} x</td>
                          </tr>
                        );
                      })
                    }
                    {
                      [0].map(() => {
                        const discount = calcAver(otherDebtHolders.map(investor => investor.discount));
                        const averPrincipal = calcAver(otherDebtHolders.map(investor => investor.principal));
                        const averPriceCap = calcAver(otherDebtHolders.map(investor => investor.price_cap));
                        const totalAcc = calcSum(otherDebtHolders.map(investor => totalAcc(investor)));
                        const { convPriceDiscount, convCap, shares, issueVal, effDiscount } = getSeriesADetails(totalAcc, discount, averPriceCap);

                        return (
                          <tr>
                            <td>Other Convertible Debt Holder(s)</td>
                            <td>$ {totalAcc}</td>
                            <td>{discount} %</td>
                            <td>$ {averPriceCap}</td>
                            <td>$ {seriesAPrice}</td>
                            <td>$ {convPriceDiscount}</td>
                            <td>{convCap !== 'n/a' ? `$ ${convCap.toFixed(2)}` : convCap}</td>
                            <td>{shares}</td>
                            <td>$ {parseInt(issueVal)}</td>
                            <td>{effDiscount}</td>
                            <td>{discount ? ((issueVal / averPrincipal).toFixed(2)) + ' x' : 'n/a'}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                  <colgroup>
                    <col className='w-[270px]' />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                  </colgroup>
                </table>
              </div>
            </div>
          </div>
          <div className="mt-10 overflow-x-auto">
            <div className="font-bold">Detailed Series A Capitalization Table</div>
            <div className="table-container w-[1500px] mt-2" ref={printRef}>
              <table className='lg-table'>
                <thead>
                  <tr className='text-center relative z-50 bg-white'>
                    <th rowSpan={2} className='pt-20'>Shareholders</th>
                    <th colSpan={4}>Common</th>
                    <th colSpan={4}>Series A</th>
                    <th colSpan={2}>Total Securities</th>
                  </tr>
                  <tr className='text-center'>
                    <th className='!border-l-0'>Common Stock</th>
                    <th>Common Options</th>
                    <th>Total Common Securities</th>
                    <th>% Common Securities</th>
                    <th>Actual $ Invested</th>
                    <th>Issue Value</th>
                    <th>Series A Shares</th>
                    <th>% of Series A</th>
                    <th>Total Securities</th>
                    <th>% Fully Diluted</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    formData.founder_shares.map((stock, index) => (
                      <tr key={index}>
                        <td>{formData.founder_names[index]}</td>
                        <td>{stock}</td>
                        <td>-</td>
                        <td>{stock}</td>
                        <td>{calcPer(stock, totalComSecurities)}</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>{stock}</td>
                        <td>{calcPer(stock, totalSecurities)}</td>
                      </tr>
                    ))
                  }
                  <tr>
                    <td>Other Employee(s)</td>
                    <td>{empStock}</td>
                    <td>-</td>
                    <td>{empStock}</td>
                    <td>{calcPer(empStock, totalComSecurities)}</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>{empStock}</td>
                    <td>{calcPer(empStock, totalSecurities)}</td>
                  </tr>
                  {
                    advisorShares.map((stock, index) => (
                      <tr key={index}>
                        <td>{formData.advisor_names[index]}</td>
                        <td>{stock}</td>
                        <td>-</td>
                        <td>{stock}</td>
                        <td>{calcPer(stock, totalComSecurities)}</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>{stock}</td>
                        <td>{calcPer(stock, totalSecurities)}</td>
                      </tr>
                    ))
                  }
                  <tr>
                    <td>Pre-Investment Option Pool</td>
                    <td>{common_stock}</td>
                    <td>{common_option}</td>
                    <td>{common_stock + common_option}</td>
                    <td>{calcPer(common_stock + common_option, totalComSecurities)}</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>{common_stock + common_option}</td>
                    <td>{calcPer(common_stock + common_option, totalSecurities)}</td>
                  </tr>
                  <tr>
                    <td>Series A Option Pool Expansion</td>
                    <td>-</td>
                    <td>{formData.series_a_option_pool}</td>
                    <td>{formData.series_a_option_pool}</td>
                    <td>{calcPer(formData.series_a_option_pool, totalComSecurities)}</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>{formData.series_a_option_pool}</td>
                    <td>{calcPer(formData.series_a_option_pool, totalSecurities)}</td>
                  </tr>
                  {
                    investors.slice(0, 3).map((investor, index) => {
                      const totalSecurity = parseInt(investor.seriesAIssueValNew / seriesAPrice);

                      return (
                        <tr key={index}>
                          <td>{investor.fullname}</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>$ {investor.principal}</td>
                          <td>$ {parseInt(investor.seriesAIssueValNew)}</td>
                          <td>{totalSecurity}</td>
                          <td>{calcPer(totalSecurity, seriesAShareTotal)}</td>
                          <td>{totalSecurity}</td>
                          <td>{calcPer(totalSecurity, totalSecurities)}</td>
                        </tr>
                      );
                    })
                  }
                  {
                    [0].map(() => {
                      const totalPrincipal = calcSum(otherDebtHolders.map(investor => investor.principal));
                      const issueVal = parseInt(calcSum(otherDebtHolders.map(investor => investor.seriesAIssueValNew)));
                      const totalSecurity = parseInt(issueVal / seriesAPrice);

                      return (
                        <tr>
                          <td>Other Convertible Debt Holder(s)</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>$ {totalPrincipal}</td>
                          <td>$ {issueVal}</td>
                          <td>{totalSecurity}</td>
                          <td>{calcPer(totalSecurity, seriesAShareTotal)}</td>
                          <td>{totalSecurity}</td>
                          <td>{calcPer(totalSecurity, totalSecurities)}</td>
                        </tr>
                      );
                    })
                  }
                  <tr>
                    <td>{formData.series_a_lead_investor[0]}</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>$ {series_a_lead_investor[1]}</td>
                    <td>$ {series_a_lead_investor[1]}</td>
                    <td>{parseInt(series_a_lead_investor[1] / seriesAPrice)}</td>
                    <td>{calcPer((series_a_lead_investor[1] / seriesAPrice), seriesAShareTotal)}</td>
                    <td>{parseInt(series_a_lead_investor[1] / seriesAPrice)}</td>
                    <td>{calcPer((series_a_lead_investor[1] / seriesAPrice), totalSecurities)}</td>
                  </tr>
                  <tr>
                    <td>Series A Other Investor(s)</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>$ {series_a_other_investors}</td>
                    <td>$ {series_a_other_investors}</td>
                    <td>{parseInt(series_a_other_investors / seriesAPrice)}</td>
                    <td>{calcPer((series_a_other_investors / seriesAPrice), seriesAShareTotal)}</td>
                    <td>{parseInt(series_a_other_investors / seriesAPrice)}</td>
                    <td>{calcPer((series_a_other_investors / seriesAPrice), totalSecurities)}</td>
                  </tr>
                  <tr className="total">
                    <td>Total</td>
                    <td>{totalStock}</td>
                    <td>{formData.series_a_option_pool}</td>
                    <td>{calcSum([totalStock, formData.series_a_option_pool])}</td>
                    <td>100%</td>
                    <td>$ {seriesAActualTotal}</td>
                    <td>$ {seriesAIssueTotal}</td>
                    <td>{parseInt(seriesAShareTotal)}</td>
                    <td>100%</td>
                    <td>{parseInt(totalSecurities)}</td>
                    <td>100%</td>
                  </tr>
                </tbody>
                <colgroup>
                  <col className='w-[270px]' />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                </colgroup>
              </table>
            </div>
          </div>

          <div className="mt-10">
            <div className="font-bold">Cap Table Summary</div>
            <div className="table-container w-[580px]">
              <table>
                <thead>
                  <tr className='text-center'>
                    <th rowSpan={2} className='pt-28'>Shareholders</th>
                    <th colSpan={2}>Pre-Investment & Option Pool Expansion</th>
                    <th colSpan={2}>Post-Series A</th>
                  </tr>
                  <tr className='text-center'>
                    <th>Fully Diluted</th>
                    <th>% FD</th>
                    <th>Fully Diluted</th>
                    <th>% FD</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Employees</td>
                    <td>{foStock + empStock}</td>
                    <td>{calcPer(foStock + empStock, totalStock)}</td>
                    <td>{foStock + empStock}</td>
                    <td>{calcPer(foStock + empStock, totalSecurities)}</td>
                  </tr>
                  <tr>
                    <td>Advisors</td>
                    <td>{adStock}</td>
                    <td>{calcPer(adStock, totalStock)}</td>
                    <td>{adStock}</td>
                    <td>{calcPer(adStock, totalSecurities)}</td>
                  </tr>
                  <tr>
                    <td>Option Pool</td>
                    <td>{common_stock + common_option}</td>
                    <td>{calcPer(common_stock + common_option, totalStock)}</td>
                    <td>{common_stock + common_option + parseInt(formData.series_a_option_pool)}</td>
                    <td>{calcPer(common_stock + common_option + parseInt(formData.series_a_option_pool), totalSecurities)}</td>
                  </tr>
                  <tr>
                    <td>Pre-Series A Investors</td>
                    <td>-</td>
                    <td>-</td>
                    <td>{parseInt(calcSum(investors.map(investor => parseInt(investor.seriesAIssueVal) + parseInt(investor.series_a))) / seriesAPrice)}</td>
                    <td>{calcPer(calcSum(investors.map(investor => parseInt(investor.seriesAIssueVal) + parseInt(investor.series_a))) / seriesAPrice, totalSecurities)}</td>
                  </tr>
                  <tr>
                    <td>Series A Investors</td>
                    <td>-</td>
                    <td>-</td>
                    <td>{parseInt(calcSum([series_a_lead_investor[1], series_a_other_investors]) / seriesAPrice)}</td>
                    <td>{calcPer(calcSum([series_a_lead_investor[1], series_a_other_investors]) / seriesAPrice, totalSecurities)}</td>
                  </tr>
                  <tr className="total">
                    <td>Total</td>
                    <td>{totalStock}</td>
                    <td>100%</td>
                    <td>{totalSecurities}</td>
                    <td>100%</td>
                  </tr>
                </tbody>
                <colgroup>
                  <col className='w-[30%]' />
                  <col className='w-[15%]' />
                  <col className='w-[15%]' />
                  <col className='w-[15%]' />
                  <col className='w-[15%]' />
                </colgroup>
              </table>
            </div>
          </div>
        </div>
      </div >

      <div className="mt-10 text-sm text-gray-pri">
        Disclaimer: Wright Squared does not assume any responsibility for any consequence resulting from your use of these documents. These documents have been prepared for informational purposes and is not intended to (a) constitute legal advice (b) create an attorney-client relationship (c) be advertising or a solicitation of any type. Each situation is highly fact specific and requires a knowledge of both state and federal laws and therefore any party should seek legal advice from a licensed attorney in the relevant jurisdictions.
      </div>
      <div className="flex items-center justify-between">
        <div className="flex gap-3 my-12">
          <img src="/img/icons/arrow-down.svg" alt="proceed" />
          <div className="text-lg font-bold">To proceed, expand “Series B” below.</div>
        </div>
        <div className="flex gap-5">
          <button className="w-48 outline-btn" onClick={restart}>BACK TO QUESTIONS</button>
          <button className="w-48 success-btn" onClick={downloadPDF}>DOWNLOAD PDF</button>
        </div>
      </div>
    </>
  );
}

export default ResultTable;