import Modal from "../../UI/Modal";

const SeriesAOtherInvestors = (props) => {
  return (
    <>
      <Modal {...props} size='sm'>
        <div className="flex flex-col gap-3">
          <div>Series A Other Investors: </div>
          <div>
            Individually, a "Series A Other Investor" and collectively, the "Series A Other Investors"
          </div>
        </div>
      </Modal>
    </>
  );
}

export default SeriesAOtherInvestors;