import Modal from "../../UI/Modal";

const DiscountRate = ({ ...props }) => {
  return (
    <>
      <Modal {...props} size='sm'>
        <div className="flex flex-col gap-3">
          <div>Discount Rate:  </div>
          <div>
            A discount is a feature that provides the Convertible Debt Holder with a reduction in the per share price upon conversion of the convertible security. 5 percent discount is given to the share price of the next round.
          </div>
          <div>
            Leave blank if n/a.
          </div>
        </div>
      </Modal>
    </>
  );
}

export default DiscountRate;