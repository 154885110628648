import { useSelector, useDispatch } from 'react-redux';
import { useRef } from 'react';
import moment from 'moment';
import { calcSum, calcAver, exportToPDF } from '@/utils';
import "@/assets/styles/table.scss";
import { updateData } from '../../redux/dataSlice';

const ResultTable = ({ restart }) => {

  const formData = useSelector((state) => state.data.form);
  const investors = formData.investors;
  const dispatch = useDispatch();
  const printRef = useRef();
  const totalPrincipal = calcSum(investors.map(investor => investor.principal));
  const otherInvestors = investors.slice(3);

  const updateInvestor = (index, key) => (e) => {
    const target = e.target;
    let value = target.innerHTML;
    const newInvestors = investors.map(obj => Object.assign({}, obj));
    newInvestors[index][key] = value;

    dispatch(updateData({
      investors: newInvestors
    }));

    // Calcuate the current offset of the cursor
    const node = target.firstChild;
    const range = window.getSelection().getRangeAt(0);
    const curOffset = range.endOffset;

    // Set the cursor position back
    requestAnimationFrame(() => {
      if (node) {
        const range = document.createRange();
        const sel = window.getSelection();
        range.setStart(node, curOffset);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
      }
    });
  }

  const updateSeriesADate = (e) => {
    const target = e.target;
    const value = target.innerHTML;

    dispatch(updateData({
      series_a_date: value
    }));

    // Calcuate the current offset of the cursor
    const node = target.firstChild;
    const range = window.getSelection().getRangeAt(0);
    const curOffset = range.endOffset;

    // Set the cursor position back
    requestAnimationFrame(() => {
      if (node) {
        const range = document.createRange();
        const sel = window.getSelection();
        range.setStart(node, curOffset);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
      }
    });
  }

  const daysHeld = (investor) => {
    const date = moment(investor?.date_issue, 'MM/DD/YYYY');
    const series_a_date = moment(formData.series_a_date, 'MM/DD/YYYY');
    const diffInDays = series_a_date.diff(date, 'days');

    return diffInDays || 0;
  }

  const interestAcc = (investor) => {
    const totalDays = daysHeld(investor);

    return parseInt(investor.principal * investor.interest / 100 * totalDays / 365);
  }

  const totalAcc = (investor) => {
    return parseInt(investor.principal) + interestAcc(investor);
  }

  const downloadPDF = () => {
    const fileName = 'Convertible Notes TABLE';
    exportToPDF(printRef.current, fileName);
  }

  return (
    <>
      <div className="flex items-start justify-between">
        <div className='w-full' ref={printRef} >
          <div className="flex justify-between">
            <div className="text-lg font-bold uppercase">“{formData.company_name}” - Convertible Notes Calculator</div>
          </div>
          <div className="mt-7" >
            <div className="font-bold">Convertible Notes Calculator</div>
            <div className="mt-2 flex">
              <div className="table-container w-[700px]">
                <div className="title">Note Inputs</div>
                <table className="striped">
                  <thead>
                    <tr className='text-center'>
                      <th>Pre-Series A Investors</th>
                      <th>Principal</th>
                      <th>Date of Issuance</th>
                      <th>Interest Rate</th>
                      <th>Discount</th>
                      <th>Cap</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      investors.map((investor, index) => (
                        <tr key={index}>
                          <td className='editable' onInput={updateInvestor(index, 'fullname')}>{investor.fullname}</td>
                          <td className='editable' onInput={updateInvestor(index, 'principal')}>{investor.principal}</td>
                          <td className='editable' onInput={updateInvestor(index, 'date_issue')}>{investor.date_issue}</td>
                          <td className='editable' onInput={updateInvestor(index, 'interest')}>{investor.interest}</td>
                          <td className='editable' onInput={updateInvestor(index, 'discount')}>{investor.discount}</td>
                          <td className='editable' onInput={updateInvestor(index, 'price_cap')}>{investor.price_cap}</td>
                        </tr>
                      ))
                    }
                    <tr className="total border-bottom-black">
                      <td>Total</td>
                      <td>$ {totalPrincipal}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>

                    {
                      investors.slice(0, 3).map(investor => (
                        <tr>
                          <td>{investor.fullname}</td>
                          <td>$ {investor.principal}</td>
                          <td>{investor.date_issue}</td>
                          <td>{investor.interest}</td>
                          <td>{investor.discount}</td>
                          <td>{investor.price_cap}</td>
                        </tr>
                      ))
                    }

                    <tr>
                      <td>Pre-Series A Other Investors</td>
                      <td>$ {calcSum(otherInvestors.map(inv => inv.principal))}</td>
                      <td className='editable'>{otherInvestors[0]?.date_issue}</td>
                      <td>{calcAver(otherInvestors.map(inv => inv.interest))}</td>
                      <td>{calcAver(otherInvestors.map(inv => inv.discount))}</td>
                      <td>{calcAver(otherInvestors.map(inv => inv.price_cap))}</td>
                    </tr>

                    <tr className="total">
                      <td>Total</td>
                      <td>$ {totalPrincipal}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>

                  <colgroup>
                    <col className='w-[35%]' />
                    <col />
                    <col />
                    <col className='w-[13%]' />
                    <col />
                    <col />
                  </colgroup>
                </table>
              </div>
              <div className="table-container w-[420px]">
                <div className="title">Note Calculations</div>
                <table className="striped">
                  <thead>
                    <tr className='text-center'>
                      <th>Date of Series A</th>
                      <th>Days Note Held</th>
                      <th>Interest Accrued</th>
                      <th>Principal & Interest</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      investors.map(investor => (
                        <tr>
                          <td className='editable' onInput={updateSeriesADate}>{formData.series_a_date}</td>
                          <td>{daysHeld(investor)}d</td>
                          <td>$ {interestAcc(investor)}</td>
                          <td>$ {totalAcc(investor)}</td>
                        </tr>
                      ))
                    }
                    <tr className="total border-bottom-black">
                      <td></td>
                      <td></td>
                      <td>$ {calcSum(investors.map(investor => interestAcc(investor)))}</td>
                      <td>$ {calcSum(investors.map(investor => totalAcc(investor)))}</td>
                    </tr>

                    {
                      investors.slice(0, 3).map(investor => (
                        <tr>
                          <td>{formData.series_a_date}</td>
                          <td>{daysHeld(investor)}d</td>
                          <td>$ {interestAcc(investor)}</td>
                          <td>$ {totalAcc(investor)}</td>
                        </tr>
                      ))
                    }

                    <tr>
                      <td>{formData.series_a_date}</td>
                      <td>{daysHeld(otherInvestors[0])}d</td>
                      <td>$ {calcAver(otherInvestors.map(inv => interestAcc(inv)))}</td>
                      <td>$ {calcAver(otherInvestors.map(inv => totalAcc(inv)))}</td>
                    </tr>

                    <tr className="total">
                      <td></td>
                      <td></td>
                      <td>$ {calcSum(investors.map(investor => interestAcc(investor)))}</td>
                      <td>$ {calcSum(investors.map(investor => totalAcc(investor)))}</td>
                    </tr>
                  </tbody>

                  <colgroup>
                    <col className='w-[120px]' />
                    <col className='w-[25%]' />
                    <col />
                    <col />
                  </colgroup>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-10 text-sm text-gray-pri">
        Disclaimer: Wright Squared does not assume any responsibility for any consequence resulting from your use of these documents. These documents have been prepared for informational purposes and is not intended to (a) constitute legal advice (b) create an attorney-client relationship (c) be advertising or a solicitation of any type. Each situation is highly fact specific and requires a knowledge of both state and federal laws and therefore any party should seek legal advice from a licensed attorney in the relevant jurisdictions.
      </div>
      <div className="flex items-center justify-between">
        <div className="flex gap-3 my-12">
          <img src="/img/icons/arrow-down.svg" alt="proceed" />
          <div className="text-lg font-bold">To proceed, expand “Series A” below.</div>
        </div>
        <div className="flex gap-5">
          <button className="w-48 outline-btn" onClick={restart}>BACK TO QUESTIONS</button>
          <button className="w-48 success-btn" onClick={downloadPDF}>DOWNLOAD PDF</button>
        </div>
      </div>
    </>
  );
}

export default ResultTable;