import PreInvestment from "../components/PreInvestment";
import ConvertibleNotes from "../components/ConvertibleNotes";
import SeriesA from "../components/SeriesA";
import SeriesB from "../components/SeriesB";
import Growth from "../components/Growth";
import Progress from "../components/Progress";
import ModalManager from "../components/ModalManager";
import { useTerms } from "../hooks";
import { useSelector, useDispatch } from 'react-redux';
import { setActiveTab } from "@/redux/progressSlice";

const Capitalization = () => {

  const activeTab = useSelector((state) => state.progress.activeTab);
  const dispatch = useDispatch();
  const changeTab = (tab) => {
    dispatch(setActiveTab(tab));
  }

  useTerms();

  return (
    <>
      <div className="capitalization px-16 py-7 h-[90vh] flex flex-col">
        <div className="flex justify-between pb-8">
          <div className="text-xl font-bold text-success-pri">Capitalization Table Evolution Scenarios</div>
          <Progress />
        </div>
        <div className="flex flex-col overflow-auto grow">
          <PreInvestment activeTab={activeTab} onClick={() => changeTab('PreInvestment')} />
          <ConvertibleNotes activeTab={activeTab} onClick={() => changeTab('ConvertibleNotes')} />
          <SeriesA activeTab={activeTab} onClick={() => changeTab('SeriesA')} />
          <SeriesB activeTab={activeTab} onClick={() => changeTab('SeriesB')} />
          <Growth activeTab={activeTab} />
        </div>
      </div>
      <ModalManager />
    </>
  )
}

export default Capitalization;