import { useState, useEffect } from "react";
import ErrorModal from "@/components/UI/ErrorModal";
import Terms from "./Terms";

const ModalManager = () => {
  const [open, setOpen] = useState(false);
  const [termOpen, setTermOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [selectedTerm, setSelectedTerm] = useState(null);
  const Term = selectedTerm && Terms[selectedTerm];

  useEffect(() => {
    window.addEventListener('option-error', (event) => {
      const error = event.detail.error;
      setErrorMsg(error);
      setOpen(true);
    });

    window.addEventListener('term-click', (event) => {
      const term = event.detail.term;
      setSelectedTerm(term);
      setTermOpen(true);
    });
  }, [])

  return (
    <>
      <ErrorModal open={open} setOpen={setOpen} text={errorMsg} />
      {
        Term && (
          <Term open={termOpen} setOpen={setTermOpen} />
        )
      }
    </>
  );
}

export default ModalManager;