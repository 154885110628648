const questions = [
  {
    'slug': 'series_b_right',
    'que': 'Do Series A Investors have a right to invest new money and participate in Series B financing? "Series A Investors" means: the (a) Pre-Series A Investors; (b) Series A Lead Investor; and (c) Series A Other Investors.',
    'input_type': 'radio',
    'options': [
      'Yes',
      'No',
    ]
  },
  {
    'slug': 'series_b_invest_exist',
    'que': 'Will Pre-Series A Investors invest new money and participate in Series B financing?',
    'input_type': 'radio',
    'options': [
      'Yes',
      'No',
    ]
  },
  {
    'slug': 'series_b_invest',
    'que': 'Complete the following table for each Convertible Debt Holder participating in Series B financing:',
    'input_type': 'custom'
  },
  {
    'slug': 'series_b_date',
    'que': 'Assumed date of Series B financing:',
    'input_type': 'date',
  },
  {
    'slug': 'premoney_valuation_series_b',
    'que': 'What is the assumed <span class="term">pre-money valuation</span> at the time of Series B financing?',
    'input_type': 'text',
    'placeholder': '$ 0.00'
  },
  {
    'slug': 'series_b_option_pool',
    'que': 'How many shares will the company expand the Series A <span class="term">option pool</span> by, if any (added prior to Series A share price calculation)?',
    'input_type': 'text',
    'placeholder': '0.00'
  },
  {
    'slug': 'series_b_lead_investor',
    'que': 'Name of the Series B Lead Investor and the investment amount:',
    'input_type': 'text_group',
    'max_num': 2,
    'placeholders': [
      'Full name',
      '$0.00'
    ]
  },
  {
    'slug': 'series_b_other_investors',
    'que': 'What is the cumulative amount invested by all other Series B investors (collectively the “Series B other Investors”)?',
    'input_type': 'text',
    'placeholder': '$0.00'
  },
];

export default questions;