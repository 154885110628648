import { useSelector } from 'react-redux';
import ProgressBar from "../UI/ProgressBar";

const Progress = () => {
  const activeIndex = useSelector((state) => state.progress.activeIndex);

  return (
    <>
      <div className="flex w-[630px] relative top-3">
        <ProgressBar max={20} value={activeIndex} width='w-[50%]' label='Pre-Investment' />
        <ProgressBar max={4} value={activeIndex - 20} width='w-[10%]' label='C-Notes' />
        <ProgressBar max={7} value={activeIndex - 24} width='w-[18%]' label='Series A' />
        <ProgressBar max={8} value={activeIndex - 31} width='w-[22%]' label='Series B' />
      </div>
    </>
  );
}

export default Progress;