import { useSelector } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { calcSum } from '@/utils';

const GrowthChart = () => {

  const formData = useSelector((state) => state.data.form);
  const investors = formData.investors;
  const { series_a_lead_investor, series_a_other_investors, series_b_lead_investor, series_b_other_investors } = formData;

  const foStock = calcSum(formData.founder_shares);
  const employeeShares = formData.employee_shares || [];
  const advisorShares = formData.advisor_shares || [];
  const empStock = calcSum(employeeShares);
  const adStock = calcSum(advisorShares);
  const common_stock = parseInt(formData.common_stock) || 0;
  const common_option = parseInt(formData.common_option) || 0;
  const totalStock = foStock + empStock + adStock + common_stock + common_option;
  const totalComSecurities = totalStock + parseInt(formData.series_a_option_pool) + parseInt(formData.series_b_option_pool);
  const seriesAPrice = (formData.premoney_valuation / totalComSecurities).toFixed(2);
  const seriesAIssueTotal = calcSum([calcSum(investors.map(investor => investor.seriesAIssueValNew)), series_a_lead_investor[1], series_a_other_investors]);
  const seriesAShareTotal = (seriesAIssueTotal / seriesAPrice).toFixed(2);
  const sharesPrior = calcSum([totalComSecurities, seriesAShareTotal]);
  const seriesBPrice = (sharesPrior / formData.premoney_valuation_series_b).toFixed(2);

  const chartData = {
    'Employees': [
      parseInt((foStock + empStock) * seriesAPrice),
      parseInt((foStock + empStock) * seriesBPrice)
    ],
    'Advisors': [
      parseInt(adStock * seriesAPrice),
      parseInt(adStock * seriesBPrice)
    ],
    'Option Pool': [
      parseInt((common_stock + common_option + parseInt(formData.series_a_option_pool)) * seriesAPrice),
      parseInt((common_stock + common_option + parseInt(formData.series_a_option_pool)) * seriesBPrice)
    ],
    'Pre-Series A Investors': [
      parseInt((calcSum(investors.map(investor => parseInt(investor.seriesAIssueVal) + parseInt(investor.series_a))) / seriesAPrice) * seriesAPrice),
      parseInt((calcSum(investors.map(investor => parseInt(investor.seriesAIssueVal) + parseInt(investor.series_a))) / seriesAPrice) * seriesBPrice)
    ],
    'Series A Investors': [
      parseInt((calcSum([series_a_lead_investor[1], series_a_other_investors[0]]) / seriesAPrice) * seriesAPrice),
      parseInt((calcSum([series_a_lead_investor[1], series_a_other_investors[0]]) / seriesAPrice) * seriesBPrice)
    ],
    'Series B Investors': [
      0,
      parseInt((calcSum([series_b_lead_investor[1], series_b_other_investors]) / seriesBPrice) * seriesBPrice)
    ],
  };

  const barColors = {
    'Employees': '#000',
    'Advisors': '#757575',
    'Option Pool': '#D9D9D9',
    'Pre-Series A Investors': '#87CDAB',
    'Series A Investors': '#34A16D',
    'Series B Investors': '#356B51',
  }

  // Create datasets for each tier
  const datasets = Object.keys(chartData).map((index) => {
    const subTierDatasets = {
      label: index,
      data: chartData[index],
      backgroundColor: barColors[index],
      borderWidth: 1,
      stack: 'Tiers',
      barThickness: 100,
    };
    return subTierDatasets;
  });


  // Chart.js data object
  const data = {
    labels: ['Series A', 'Series B'],
    datasets: datasets
  };

  // Chart.js options
  const options = {
    scales: {
      x: {
        grid: {
          display: false
        },
      },
      y: {
        ticks: {
          callback: function (value, index, values) {
            return `$${(value / 1000).toFixed(0) + (value === 0 ? '' : 'K')}`;
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'right',
        labels: {
          boxWidth: 25,
          boxHeight: 25,
          padding: 15,
          font: {
            size: 14
          }
        }
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.dataset.label;
            const value = context.formattedValue;
            return `${label}: $${value}`;
          },
        }
      }
    },

  };

  return (
    <>
      <div className='w-[750px] mt-6'>
        <Bar data={data} options={options} />
      </div>
    </>
  );
}

export default GrowthChart;