import { useSelector } from 'react-redux';
import { useRef } from 'react';
import { calcSum, calcPer, exportToPDF } from '@/utils';

const ResultTable = () => {

  const formData = useSelector((state) => state.data.form);
  const investors = formData.investors;
  const printRef = useRef();
  const { series_a_lead_investor, series_a_other_investors, series_b_lead_investor, series_b_other_investors } = formData;

  const foStock = calcSum(formData.founder_shares);
  const employeeShares = formData.employee_shares || [];
  const advisorShares = formData.advisor_shares || [];
  const empStock = calcSum(employeeShares);
  const adStock = calcSum(advisorShares);
  const common_stock = parseInt(formData.common_stock) || 0;
  const common_option = parseInt(formData.common_option) || 0;
  const totalStock = foStock + empStock + adStock + common_stock + common_option;
  const totalComSecurities = totalStock + parseInt(formData.series_a_option_pool) + parseInt(formData.series_b_option_pool);
  const seriesAPrice = (formData.premoney_valuation / totalComSecurities).toFixed(2);
  const seriesAIssueTotal = calcSum([calcSum(investors.map(investor => investor.seriesAIssueValNew)), series_a_lead_investor[1], series_a_other_investors]);
  const seriesAShareTotal = (seriesAIssueTotal / seriesAPrice).toFixed(2);
  const sharesPrior = calcSum([totalComSecurities, seriesAShareTotal]);
  const beforeSeriesBShareTotal = sharesPrior - parseInt(formData.series_b_option_pool);
  const seriesBPrice = (sharesPrior / formData.premoney_valuation_series_b).toFixed(2);
  const seriesBIssueTotal = calcSum([calcSum(investors.map(investor => investor.series_b)), series_a_lead_investor[2], series_a_other_investors[1], series_b_lead_investor[1], series_b_other_investors]);
  const seriesBShareTotal = parseInt(seriesBIssueTotal / seriesBPrice);
  const totalSecurities = calcSum([sharesPrior, seriesBShareTotal]);

  const downloadPDF = () => {
    const fileName = 'Growth Table';
    exportToPDF(printRef.current, fileName);
  }

  return (
    <>
      <div className="flex items-start justify-between">
        <div className='w-full'>

          <div className="mt-10">
            <div className="font-bold">Cap Table After</div>
            <div className="table-container w-[900px]" ref={printRef}>
              <table>
                <thead>
                  <tr className='relative z-50 text-center bg-white'>
                    <th rowSpan={2} className='pt-28'>Shareholders</th>
                    <th colSpan={3}>Series A</th>
                    <th colSpan={3}>Series B</th>
                  </tr>
                  <tr className='text-center'>
                    <th>Fully Diluted</th>
                    <th>% FD</th>
                    <th>Value</th>
                    <th>Fully Diluted</th>
                    <th>% FD</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Employees</td>
                    <td>{foStock + empStock}</td>
                    <td>{calcPer(foStock + empStock, beforeSeriesBShareTotal)}</td>
                    <td>${parseInt((foStock + empStock) * seriesAPrice)}</td>
                    <td>{foStock + empStock}</td>
                    <td>{calcPer(foStock + empStock, totalSecurities)}</td>
                    <td>${parseInt((foStock + empStock) * seriesBPrice)}</td>
                  </tr>
                  <tr>
                    <td>Advisors</td>
                    <td>{adStock}</td>
                    <td>{calcPer(adStock, beforeSeriesBShareTotal)}</td>
                    <td>$ {parseInt(adStock * seriesAPrice)}</td>
                    <td>{adStock}</td>
                    <td>{calcPer(adStock, totalSecurities)}</td>
                    <td>$ {parseInt(adStock * seriesBPrice)}</td>
                  </tr>
                  <tr>
                    <td>Option Pool</td>
                    <td>{common_stock + common_option + parseInt(formData.series_a_option_pool)}</td>
                    <td>{calcPer(common_stock + common_option + parseInt(formData.series_a_option_pool), beforeSeriesBShareTotal)}</td>
                    <td>$ {parseInt((common_stock + common_option + parseInt(formData.series_a_option_pool)) * seriesAPrice)}</td>
                    <td>{common_stock + common_option + parseInt(formData.series_a_option_pool)}</td>
                    <td>{calcPer(common_stock + common_option + parseInt(formData.series_a_option_pool), totalSecurities)}</td>
                    <td>$ {parseInt((common_stock + common_option + parseInt(formData.series_a_option_pool)) * seriesBPrice)}</td>
                  </tr>
                  <tr>
                    <td>Pre-Series A Investors</td>
                    <td>{parseInt(calcSum(investors.map(investor => parseInt(investor.seriesAIssueVal) + parseInt(investor.series_a))) / seriesAPrice)}</td>
                    <td>{calcPer(calcSum(investors.map(investor => parseInt(investor.seriesAIssueVal) + parseInt(investor.series_a))) / seriesAPrice, beforeSeriesBShareTotal)}</td>
                    <td>$ {parseInt((calcSum(investors.map(investor => parseInt(investor.seriesAIssueVal) + parseInt(investor.series_a))) / seriesAPrice) * seriesAPrice)}</td>
                    <td>{parseInt(calcSum(investors.map(investor => parseInt(investor.seriesAIssueVal) + parseInt(investor.series_a))) / seriesAPrice)}</td>
                    <td>{calcPer(calcSum(investors.map(investor => parseInt(investor.seriesAIssueVal) + parseInt(investor.series_a))) / seriesAPrice, totalSecurities)}</td>
                    <td>$ {parseInt((calcSum(investors.map(investor => parseInt(investor.seriesAIssueVal) + parseInt(investor.series_a))) / seriesAPrice) * seriesBPrice)}</td>
                  </tr>
                  <tr>
                    <td>Series A Investors</td>
                    <td>{parseInt(calcSum([series_a_lead_investor[1], series_a_other_investors[0]]) / seriesAPrice)}</td>
                    <td>{calcPer(calcSum([series_a_lead_investor[1], series_a_other_investors[0]]) / seriesAPrice, beforeSeriesBShareTotal)}</td>
                    <td>$ {parseInt((calcSum([series_a_lead_investor[1], series_a_other_investors[0]]) / seriesAPrice) * seriesAPrice)}</td>
                    <td>{parseInt(calcSum([series_a_lead_investor[1], series_a_other_investors[0]]) / seriesAPrice + calcSum([series_a_lead_investor[2], series_a_other_investors[1]]) / seriesBPrice)}</td>
                    <td>{calcPer(calcSum([series_a_lead_investor[1], series_a_other_investors]) / seriesAPrice, totalSecurities)}</td>
                    <td>$ {parseInt((calcSum([series_a_lead_investor[1], series_a_other_investors[0]]) / seriesAPrice) * seriesBPrice)}</td>
                  </tr>
                  <tr>
                    <td>Series B Investors</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>{parseInt(calcSum([series_b_lead_investor[1], series_b_other_investors]) / seriesBPrice)}</td>
                    <td>{calcPer(calcSum([series_b_lead_investor[1], series_b_other_investors]) / seriesBPrice, totalSecurities)}</td>
                    <td>$ {parseInt((calcSum([series_b_lead_investor[1], series_b_other_investors]) / seriesBPrice) * seriesBPrice)}</td>
                  </tr>
                  <tr className="total">
                    <td>Total</td>
                    <td>{beforeSeriesBShareTotal}</td>
                    <td>100%</td>
                    <td>$ {parseInt(beforeSeriesBShareTotal * seriesAPrice)}</td>
                    <td>{totalSecurities}</td>
                    <td>100%</td>
                    <td>$ {parseInt(totalSecurities * seriesBPrice)}</td>
                  </tr>
                </tbody>
                <colgroup>
                  <col className='w-[22%]' />
                  <col className='w-[14%]' />
                  <col className='w-[10%]' />
                  <col className='w-[14%]' />
                  <col className='w-[15%]' />
                  <col className='w-[10%]' />
                  <col className='w-[15%]' />
                </colgroup>
              </table>
            </div>

            <div className="mt-10 text-sm text-gray-pri">
              Disclaimer: Wright Squared does not assume any responsibility for any consequence resulting from your use of these documents. These documents have been prepared for informational purposes and is not intended to (a) constitute legal advice (b) create an attorney-client relationship (c) be advertising or a solicitation of any type. Each situation is highly fact specific and requires a knowledge of both state and federal laws and therefore any party should seek legal advice from a licensed attorney in the relevant jurisdictions.
            </div>
            <div className="flex justify-end my-10">
              <button className="w-48 success-btn" onClick={downloadPDF}>DOWNLOAD PDF</button>
            </div>
          </div>
        </div>
      </div >
    </>
  );
}

export default ResultTable;