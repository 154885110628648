import { useSelector, useDispatch } from 'react-redux';
import Chart from './Chart';
import ResultTable from './ResultTable';
import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '@/assets/styles/toast.scss';
import { setActiveTab } from '../../redux/progressSlice';

const Growth = ({ activeTab }) => {

  const [isActive, setIsActive] = useState(false);
  const dispatch = useDispatch();
  const curStep = useSelector((state) => state.progress.curStep);
  const companyName = useSelector((state) => state.data.form.company_name);

  useEffect(() => {
    setIsActive((activeTab === 'Growth') ? true : false);
  }, [activeTab])

  const onTabClick = () => {
    if (curStep !== 'Growth') {
      toast.info('Complete Series B questions to view Value Growth Chart');
    } else {
      dispatch(setActiveTab('Growth'));
    }
  }

  return (
    <>
      {
        !isActive && (
          <div className="px-6 py-3 text-gray-pri border-gray-sec bg-gray-thi border-bottom-gray-sec">
            <button onClick={onTabClick} className="flex items-center gap-2">
              <div className="font-bold">GROWTH</div>
              <img src="/img/icons/plus.svg" className="invert" alt="expand" />
            </button>
          </div>
        )
      }

      {
        isActive && (
          <div className={`flex flex-col overflow-hidden border-gray-sec ${isActive && 'grow'}`}>
            <div className="px-6 py-3">
              <div className="font-bold">GROWTH</div>
            </div>
            <div className='px-10 overflow-scroll'>

              <div className="text-lg font-bold uppercase mt-7">
                “{companyName}” - value growth
              </div>

              <Chart />
              <ResultTable />

            </div>
          </div>
        )
      }

      <ToastContainer />
    </>
  );
}

export default Growth;