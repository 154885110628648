import Footer from "../components/Footer";

const HomePage = () => {
  return (
    <div>
      <div className="main h-[90vh] flex flex-col sm:justify-center relative max-md:pt-20">
        <div className="flex flex-col items-center text-white w-full sm:w-[540px] sm:ml-[8vw]">
          <img className="max-md:w-24" src="./img/logo_large_white.png" alt="logo" />
          <img className="mt-8 max-sm:w-64" src="./img/icons/tagline_white.svg" alt="tagline" />
          <div className="mt-4 text-sm sm:text-3xl">Big city experience, small town service.</div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default HomePage;