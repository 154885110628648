import Modal from "../../UI/Modal";

const CommonStockOptions = ({ ...props }) => {
  return (
    <>
      <Modal {...props} size='sm'>
        <div className="flex flex-col gap-3">
          <div>Common stock options: </div>
          <div>
            Common stock options are a right to acquire a certain number of the company’s common shares at a predefined price (“Exercise Price”), generally, after a certain amount of time.
          </div>
          <div>
            Stock options represent a future right, subject to the terms of an option plan, to buy those shares at a discounted rate (assuming the value of the company has increased since the grant date).
          </div>
          <div>
            Holders of common stock options are not stockholders, do not vote like stockholders, and are merely holders of a contractual right to acquire shares.
          </div>
        </div>
      </Modal>
    </>
  );
}

export default CommonStockOptions;