import { Link } from "react-router-dom";
import { useState } from "react";
import SidePanel from "../components/SidePanel";
import useFieldFormatter from 'format-as-you-type';
import { ToastContainer, toast } from 'react-toastify';
import { formatPhoneNumber, validateEmail } from "../utils";
import 'react-toastify/dist/ReactToastify.css';
import '@/assets/styles/toast.scss';

const Contact = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const phoneNumberFormatter = useFieldFormatter(formatPhoneNumber, setPhoneNumber);
  const [clearEnable, setClearEnable] = useState(false);
  const [submitEnable, setSubmitEnable] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhoneNum, setIsValidPhoneNum] = useState(true);

  const onFormChange = () => {
    const form = document.forms[0];
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());
    const values = Object.values(formJson);
    const filledValNum = (values.filter(value => value !== '')).length;
    setClearEnable(filledValNum > 0);
    setSubmitEnable(filledValNum === values.length);
  }

  const onEmailChange = (e) => {
    // Email validation
    const email = e.target.value;
    setIsValidEmail(validateEmail(email));
  }

  const onPhoneNumChange = (e) => {
    const samplePhoneNum = '(567) 434-4344';
    setIsValidPhoneNum(phoneNumber.length === samplePhoneNum.length);
  }

  const onFormSubmit = (e) => {
    e.preventDefault();
    const form = document.forms[0];
    const formData = new FormData(form);
    fetch('send_email.php', {
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          toast.info("Message sent successfully.");
          form.reset(); // Reset the form
        } else {
          toast.info("Failed to send message. Please try again.");
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  const clearForm = () => {
    const form = document.forms[0];

    Array.from(form.elements).forEach(input => {
      input.value = '';
    });

    setPhoneNumber('');
    setClearEnable(false);
  }

  return (
    <>
      <div className="flex main h-[90vh] tab-active">
        <SidePanel />
        <div className="w-full relative lg:w-[54vw] pt-6 overflow-y-auto bg-white flex flex-col">
          <div className="px-5 md:px-20 grow max-sm:text-xs">
            <div>
              <sup className="mr-1 text-danger">*</sup> INDICATES REQUIRED FIELD
            </div>
            <form className="flex flex-col gap-5 mt-6" onSubmit={onFormSubmit}>
              <div className="flex gap-5 sm:gap-8 max-sm:flex-col">
                <div className="w-full">
                  <div className="input-desc required">First name</div>
                  <input type="text" name="firstname" onChange={onFormChange} className="w-full mt-1" />
                </div>
                <div className="w-full">
                  <div className="input-desc required">Last name</div>
                  <input type="text" name="lastname" onChange={onFormChange} className="w-full mt-1" />
                </div>
              </div>
              <div className="flex gap-5 sm:gap-8 max-sm:flex-col">
                <div className="w-full">
                  <div className="flex items-end justify-between">
                    <div className="input-desc required">Phone</div>
                    {!isValidPhoneNum && (
                      <div className="text-xs xl:text-sm text-danger">Please enter a valid phone number.</div>
                    )}
                  </div>
                  <input type="text" name="phone" onChange={onFormChange} value={phoneNumber} {...phoneNumberFormatter} placeholder="(xxx) xxx-xxxx" onBlur={onPhoneNumChange} className={`w-full mt-1 ${!isValidPhoneNum && 'invalid'}`} />
                </div>
                <div className="w-full">
                  <div className="flex items-end justify-between">
                    <div className="input-desc required">Email</div>
                    {!isValidEmail && (
                      <div className="text-xs xl:text-sm text-danger">Please enter a valid email address.</div>
                    )}
                  </div>
                  <input type="email" name="email" onChange={onFormChange} onBlur={onEmailChange} placeholder="name@company.com" className={`w-full mt-1 ${!isValidEmail && 'invalid'}`} />
                </div>
              </div>
              <div>
                <div className="input-desc">Company </div>
                <input type="text" name="company" onChange={onFormChange} placeholder="Company or Organization Name" className="w-full mt-1" />
              </div>
              <div>
                <div className="input-desc required">Reason for contact</div>
                <div className="overflow-auto border-gray-sec">
                  <textarea maxLength={5000} type="text" name="reason" onChange={onFormChange} rows={6} placeholder="Please enter your inquiry or reason for contact." className="w-full border-none" />
                  <div className="relative float-right pr-5 text-sm text-gray-pri">Max. character limit: 5000</div>
                </div>
                <div className="mt-5 text-sm text-gray-pri">
                  Use of the above form to transmit information to Wright Squared does not create an attorney-client relationship and such information will not necessarily be treated as confidential or privileged.
                </div>
              </div>
              <div className="flex justify-between gap-12 mb-5 sm:justify-end">
                <button className="outline-btn" onClick={clearForm} disabled={!clearEnable}>CLEAR FORM</button>
                <button className="success-btn" disabled={!submitEnable}>SUBMIT</button>
              </div>
            </form>
          </div>

          <div className="flex justify-between w-full gap-3 px-5 py-6 text-xs text-white bg-black sm:text-sm max-md:flex-col md:px-20">
            <a target="_blank" rel="noreferrer" className="flex items-center gap-2" href="https://maps.app.goo.gl/Fo415AVb8MF8RvNL7">
              <img className="max-sm:w-3" src="/img/icons/pin.svg" alt="pin" />
              <span>420 Nichols Rd., Suite 64, Kansas City, MO 64112</span>
            </a>
            <Link className="flex items-center gap-2 max-sm:w-3" to="mailto:info@wrightsquared.net">
              <img src="/img/icons/email.svg" alt="email" />
              <span>info@wrightquared.net</span>
            </Link>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Contact;