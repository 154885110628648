import { formatString, formatDate } from 'format-as-you-type';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const formatPhoneNumber = (newInput, options) => {
  const numericInput = newInput.replace(/\D/g, '');
  return formatString(numericInput, '(000) 000-0000', options);
};

export const formatInputDate = (newInput, options) => {
  return formatDate(newInput, options, 'DD/MM/YY');
};

export const formatNumber = (value) => {
  const numericValue = value.replace(/[^\d]/g, '');
  return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const validateEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export const calcSum = (array) => {
  return array.reduce((sum, cur) => sum + parseInt(cur) || 0, 0);
}

export const calcMin = (array) => {
  const numArray = array.map(val => parseFloat(val));
  return Math.min(...numArray);
}

export const calcAver = (array) => {
  return parseInt((calcSum(array) / array.length)) || 0;
}

export const calcPer = (value, total) => {
  return `${(value / total * 100).toFixed(1)} %`;
}

export const exportToPDF = async (element, filename) => {

  html2canvas(element, { scale: "3" }).then(canvas => {
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF();
    const padding = 10;
    const imgProps = pdf.getImageProperties(imgData);
    let pdfWidth = pdf.internal.pageSize.getWidth() - 2 * padding;
    let pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

    // Check if the calculated height extends beyond page height, accounting for padding
    if (pdfHeight > pdf.internal.pageSize.getHeight() - 2 * padding) {
      pdfHeight = pdf.internal.pageSize.getHeight() - 2 * padding;
      pdfWidth = (imgProps.width * pdfHeight) / imgProps.height;
    }

    pdf.addImage(imgData, 'PNG', padding, padding, pdfWidth, pdfHeight);
    pdf.save(filename);
  });
}

export const setOffsetBack = (target) => {
  const node = target.firstChild;
  const range = window.getSelection().getRangeAt(0);
  const curOffset = range.endOffset;

  requestAnimationFrame(() => {
    if (node) {
      const range = document.createRange();
      const sel = window.getSelection();
      range.setStart(node, curOffset);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  });
}